/* eslint-disable camelcase */
import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Card,
  List,
  Grid,
  Box,
  Tooltip,
  Typography
} from '@material-ui/core';

import {
  deregisterDevices,
  moveDevicesToNetwork,
  removeDevicesFromNetwork
} from '../../../actions/device';
import { dialogOpen } from '../../../actions/dialog';

// ----------------------------------------------------------------------

function ItemValue(props) {
  const { item, value } = props;
  return (
    <Grid container item direction="row" rowSpacing={2}>
      <Grid item xs={7}>
        <Typography variant="subtitle2" sx={{ paddingTop: 1, paddingBottom: 1, paddingLeft: 2 }}>
          {item}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="body2" sx={{ paddingTop: 1, paddingBottom: 1, paddingRight: 2 }}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default function DeviceMoreMenu({ location, device, networkItems }) {
  // const ref = useRef(null);
  // const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // const dispatch = useDispatch();
  const { id, serial_number, connection, cpu_load, memory_usage } = device;
  const { wan_ip } = connection;
  const open = Boolean(anchorEl);
  const btnId = open ? `more-btn${id}` : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="More Info">
        <IconButton aria-describedby={btnId} onClick={handleClick}>
          <Icon icon={moreVerticalFill} width={20} height={20} />
        </IconButton>
      </Tooltip>
      <Popover
        id={btnId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box sx={{ flexGrow: 1, minWidth: 260 }}>
          <Grid container direction="column" columns={16}>
            <ItemValue item="SN" value={serial_number} />
            <ItemValue item="WAN IP" value={wan_ip} />
            <ItemValue item="CPU Usage" value={(cpu_load && `${cpu_load}%`) || ''} />
            <ItemValue item="Mem. Usage" value={(memory_usage && `${memory_usage.used}%`) || ''} />
          </Grid>
        </Box>
      </Popover>
    </>
  );
}
