/* eslint-disable camelcase */
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import plusFill from '@iconify/icons-eva/plus-fill';
import fileAdd from '@iconify/icons-eva/file-add-fill';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import Edit from '@iconify/icons-eva/edit-2-outline';
import { alpha, styled } from '@material-ui/core/styles';
// import ArrowUp from '@iconify/icons-eva/arrow-upward-outline';
// import ArrowDown from '@iconify/icons-eva/arrow-downward-outline';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Box,
  Link,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Backdrop,
  IconButton,
  Tooltip,
  Slide,
  Grid,
  Scrollbar,
  ClickAwayListener,
  SvgIcon
} from '@material-ui/core';
import SignalPercent from '../../components/SignalPercent';
// import { dialogClose, dialogSetData } from '../../actions/scanDialog';
import { scan } from '../../actions/device';
import { snackbarError } from '../../actions/snackbar';
import { backdropShow, backdropClose } from '../../actions/backdrop';
import { UptimeString } from '../../utils/formatTime';
import { formatBytes, formatBps } from '../../utils/formatNumber';

const TABLE_HEAD = [
  { id: 'number', label: '#', alignRight: false },
  { id: 'quality', label: 'Quality', alignRight: false },
  { id: 'signal', label: 'Signal', alignRight: false },
  { id: 'ssid', label: 'SSID', alignRight: false },
  { id: 'channel', label: 'Channel', alignRight: false },
  { id: 'mode', label: 'Mode', alignRight: false },
  { id: 'bssid', label: 'BSSID', alignRight: false },
  { id: 'security', label: 'Security', alignRight: false }
  // { id: 'join', label: '', alignRight: false }
];

function ScanRow(props) {
  // const dispatch = useDispatch();

  const { idx, row, device, bssidDeviceMap } = props;

  const { ssid, bssid, mode, security, channel, signal } = row;

  // const { id, org_id, network_id, name, mac } = device;

  let bssidDisply = bssid;
  const matchedDevice = bssidDeviceMap[bssid];
  if (matchedDevice) {
    bssidDisply = `${bssid} (${matchedDevice.name})`;
  }

  return (
    <>
      <TableRow hover key={idx} tabIndex={-1}>
        <TableCell align="left">{idx + 1}</TableCell>
        <TableCell align="left">
          <SignalPercent signal={signal} />
        </TableCell>
        <TableCell align="left">{signal}</TableCell>
        <TableCell align="left">{ssid}</TableCell>
        <TableCell align="left">{channel}</TableCell>
        <TableCell align="left">{mode}</TableCell>
        <TableCell align="left">{bssidDisply}</TableCell>
        <TableCell align="left">{security}</TableCell>
        {/* <TableCell align="left">Join</TableCell> */}
      </TableRow>
    </>
  );
}

export default function ScanDialog({ device, isOpen, setClose, bssidDeviceMap, band }) {
  // const { title, open, setOpen, defName, defCountryCode, defTimeZone, onApply } = props;
  // const [name, setName] = useState(defName);
  // const [isLoading, setIsLoading] = useState(false);
  // const [isJoining, setIsJoining] = useState(false);
  const { scanned_aps } = useSelector((state) => state.scan);
  const { open: isBackdropOpen } = useSelector((state) => state.backdrop);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(backdropShow());
      dispatch(scan(device.org_id, device.network_id, device.id, band))
        .catch((err) => dispatch(snackbarError(err.message)))
        .finally(() => {
          dispatch(backdropClose());
        });
    } else {
      dispatch(backdropClose());
    }
  }, [isOpen, band]);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    dispatch(backdropClose());
    setClose();
  }, []);

  return (
    <Dialog
      fullWidth
      open={isOpen && !isBackdropOpen}
      maxWidth="xl"
      onClose={handleClose}
      aria-labelledby="scan-dialog-title"
    >
      <DialogTitle id="scan-dialog-title">{device && `${device.name} Scan Results`}</DialogTitle>
      <DialogContent>
        <TableContainer sx={{ minWidth: 1200 }}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((column) => (
                  <TableCell key={column.id} align={column.alignRight ? 'right' : 'left'}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {scanned_aps.map((row, idx) => (
                <ScanRow
                  key={idx}
                  idx={idx}
                  row={row}
                  device={device}
                  bssidDeviceMap={bssidDeviceMap}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
