/* eslint-disable camelcase */
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import plusFill from '@iconify/icons-eva/plus-fill';
import fileAdd from '@iconify/icons-eva/file-add-fill';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import Edit from '@iconify/icons-eva/edit-2-outline';
import { alpha, styled } from '@material-ui/core/styles';
// import ArrowUp from '@iconify/icons-eva/arrow-upward-outline';
// import ArrowDown from '@iconify/icons-eva/arrow-downward-outline';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {
  Box,
  Link,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Backdrop,
  IconButton,
  Tooltip,
  Slide,
  Grid,
  Scrollbar,
  ClickAwayListener,
  SvgIcon
} from '@material-ui/core';
import SignalPercent from '../../components/SignalPercent';

import { UptimeString } from '../../utils/formatTime';
import { formatBytes, formatBps } from '../../utils/formatNumber';

const TreeRootStyles = styled('div')(({ theme }) => ({
  // top: 60,
  // left: 20,
  top: 80,
  left: 190,
  // right: 0,
  // bottom: 0,
  // justifyContent: 'center',
  // alignItems: 'center',
  zIndex: 99,
  overflow: 'auto',
  minWidth: '300px',
  width: 'auto',
  maxHeight: 'calc(100vh - 60px)',
  height: '100',
  display: 'flex',
  // flexDirection: 'column',
  position: 'fixed',
  flexDirection: 'column',
  alignItems: 'left',
  borderRadius: 12,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(2, 2),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.95)}`,
  color: theme.palette.text.primary,
  '&:hover > $content': {
    backgroundColor: theme.palette.action.hover
  },
  '&:focus > $content, &$selected > $content': {
    backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
    color: 'var(--tree-view-color)'
  },
  '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
    backgroundColor: 'transparent'
  }
}));

const TABLE_HEAD = [
  { id: 'mac', label: 'Client MAC', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'radio', label: 'Radio', alignRight: false },
  { id: 'quality', label: 'Quality', alignRight: false },
  { id: 'signal', label: 'Signal', alignRight: false },
  { id: 'snr', label: 'SNR', alignRight: false },
  { id: 'ssid', label: 'SSID', alignRight: false },
  { id: 'link_rate', label: 'Link Rate', alignRight: false },
  { id: 'total_traffic', label: 'Total Traffic', alignRight: false },
  { id: 'session_time', label: 'Session Time', alignRight: false },
  { id: 'avg_thrput', label: 'Avg. Throughtput', alignRight: false }
];

function UpDownString(props) {
  const { up, down } = props;
  return (
    <>
      <Box display="flex" alignItems="center">
        <ArrowUpwardIcon sx={{ fontSize: 16 }} />
        {up}
        <br />
      </Box>
      <Box display="flex" alignItems="center">
        <ArrowDownwardIcon sx={{ fontSize: 16 }} />
        {down}
      </Box>
    </>
  );
}

export default function ClientList({ device, isOpen, setClose, bssidDeviceMap }) {
  const clients = useMemo(() => {
    if (!device || !device.radios || !isOpen) return [];

    const ret = [];
    Object.entries(device.radios).forEach(([key, radio]) => {
      const { clients } = radio;
      const radioType = key === 'band_2g' ? '2.4GHz' : '5GHz';
      if (clients) {
        clients.forEach((c) => {
          ret.push({
            ...c,
            radio: radioType,
            ssid: radio.ssid
          });
        });
      }
    });
    return ret;
  }, [device, isOpen]);

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = devices.map((d) => d.id);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // console.log('isOpen', isOpen);
  // console.log('device is null', device === null);

  return (
    <ClickAwayListener onClickAway={setClose}>
      <Grid>
        <Slide direction="left" in={isOpen} mountOnEnter unmountOnExit>
          <TreeRootStyles>
            <Grid container justifyContent="flex-start">
              {device && <Typography variant="h5">{device.name}</Typography>}
            </Grid>
            <TableContainer sx={{ minWidth: 600 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.alignRight ? 'right' : 'left'}
                        // style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clients.map((row) => {
                    const {
                      host_mode,
                      mac,
                      rx_bytes,
                      rx_rate,
                      signal,
                      snr,
                      time,
                      tx_bytes,
                      tx_rate,
                      ssid,
                      radio
                    } = row;

                    let macDisply = mac;
                    const clientDevice = bssidDeviceMap[mac];
                    if (clientDevice) {
                      macDisply = `${mac} (${clientDevice.name})`;
                    }

                    return (
                      <TableRow
                        hover
                        key={`${mac}-${radio}`}
                        tabIndex={-1}
                        role="checkbox"
                        // selected={isItemSelected}
                        // aria-checked={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            />
                          </TableCell> */}
                        <TableCell align="left">{macDisply}</TableCell>
                        <TableCell align="left">
                          {!host_mode || host_mode.startsWith('AP') ? 'Station' : 'AP'}
                        </TableCell>
                        <TableCell align="left">{radio}</TableCell>
                        <TableCell align="left">
                          <SignalPercent signal={signal} />
                        </TableCell>
                        <TableCell align="left">{`${signal} dBm`}</TableCell>
                        <TableCell align="left">{(snr && `${snr} dB`) || ''}</TableCell>
                        <TableCell align="left">{ssid}</TableCell>
                        <TableCell align="left">
                          <UpDownString up={rx_rate} down={tx_rate} />
                        </TableCell>
                        <TableCell align="left">
                          <UpDownString up={formatBytes(rx_bytes)} down={formatBytes(tx_bytes)} />
                        </TableCell>
                        <TableCell>{UptimeString(time)}</TableCell>
                        <TableCell>
                          <UpDownString
                            up={formatBps((rx_bytes * 8) / time)}
                            down={formatBps((tx_bytes * 8) / time)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </TreeRootStyles>
        </Slide>
      </Grid>
    </ClickAwayListener>
  );
}
