import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function formatUnit(bytes, decimals, base, units) {
  if (!bytes) {
    return `0 ${units[0]}`;
  }
  const k = base || 1024;
  const dm = decimals === 0 ? 0 : decimals || 2;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${units[i]}`;
}

/**
 * translate numbers to correct format with specific unit and decimals
 * @param {number} bytes - numbers
 * @param {number} [decimals] - decimal
 * @param {number} [base] - base
 */
export function formatBps(bytes, decimals, base) {
  const units = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps'];

  return formatUnit(bytes, decimals, 1000, units);
}

/**
 * translate numbers to correct format with specific unit and decimals
 * @param {number} bytes - numbers
 * @param {number} [decimals] - decimal
 * @param {number} [base] - base
 */
export function formatBytes(bytes, decimals, base) {
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  return formatUnit(bytes, decimals, base, units);
}
