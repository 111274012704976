/* eslint-disable camelcase */
import { Icon } from '@iconify/react';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import listFill from '@iconify/icons-eva/list-fill';
import { alpha, styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Folder from '@material-ui/icons/FolderOutlined';
import Business from '@material-ui/icons/Business';
import Room from '@material-ui/icons/Room';
import Plus from '@iconify/icons-eva/plus-outline';
import FolderAdd from '@iconify/icons-eva/folder-add-outline';
import Settings from '@iconify/icons-eva/settings-2-outline';
import Edit from '@iconify/icons-eva/edit-2-outline';
import Trash from '@iconify/icons-eva/trash-2-outline';
import Delete from '@material-ui/icons/Delete';
import AccountTree from '@material-ui/icons/AccountTreeOutlined';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Grid from '@material-ui/core/Grid';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import {
  Breadcrumbs,
  useMediaQuery,
  Typography,
  Tooltip,
  Box,
  Input,
  Slide,
  Button,
  InputAdornment,
  Link,
  ClickAwayListener,
  IconButton
} from '@material-ui/core';

import { dialogOpen } from '../../actions/dialog';
import { setLocation } from '../../actions/location';
import {
  addOrg,
  delOrg,
  modifyOrg,
  addGroup,
  delGroup,
  modifyGroup,
  addNetwork,
  delNetwork,
  modifyNetwork
} from '../../actions/org';

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const TreeRootStyles = styled('div')(({ theme }) => ({
  zIndex: 99,
  overflow: 'auto',
  minWidth: '250px',
  width: 'auto',
  maxHeight: 'calc(100vh - 60px)',
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  alignItems: 'left',
  borderRadius: 12,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(2, 2),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.95)}`,
  color: theme.palette.text.primary,
  '&:hover > $content': {
    backgroundColor: theme.palette.action.hover
  },
  '&:focus > $content, &$selected > $content': {
    backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
    color: 'var(--tree-view-color)'
  },
  '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
    backgroundColor: 'transparent'
  }
}));

// const TreeContentStyles = styled('div')(({ theme }) => ({
//   color: theme.palette.text.secondary,
//   borderTopRightRadius: theme.spacing(2),
//   borderBottomRightRadius: theme.spacing(2),
//   paddingRight: theme.spacing(1),
//   fontWeight: theme.typography.fontWeightMedium,
//   '$expanded > &': {
//     fontWeight: theme.typography.fontWeightRegular
//   }
// }));

// const TreeGroupStyles = styled('div')(({ theme }) => ({
//   marginLeft: 0,
//   '& $content': {
//     paddingLeft: theme.spacing(2)
//   }
// }));

// const TreeLabelStyles = styled('div')(({ theme }) => ({
//   fontWeight: 'inherit',
//   color: 'inherit'
// }));

const TreeLabelRootStyles = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 0.5)
}));

// const TreeLabelIconStyles = styled('div')(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   alignItems: 'center',
//   marginRight: theme.spacing(0.8)
// }));

// const TreeLabelTextStyles = styled('div')(({ theme }) => ({
//   display: 'flex',
//   justifyContent: 'flex-start',
//   fontWeight: 'inherit',
//   flexGrow: 1
// }));

const TreeLocationTextStyles = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  marginLeft: theme.spacing(0.5)
}));

// const TreeItemRootStyles = styled('div')(({ theme }) => ({
//   flexDirection: 'row',
//   flexWrap: 'nowrap',
//   flexGrow: 1
// }));

export default function OrgTree() {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [hoveringId, setHoveringId] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [locationText, setLocationText] = useState([]);
  const { user } = useSelector((state) => state.user);
  const { open: isDialogOpen } = useSelector((state) => state.dialog);
  const orgs = useSelector((state) => state.orgs);
  const location = useSelector((state) => state.location);

  const handleToggle = (event, nodeIds) => {
    // console.log('setExpanded', nodeIds);
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const treeItems = useMemo(() => {
    const nextGroup = (org, grp, parent) => {
      const group = {
        ...grp,
        itemType: 'group',
        parent,
        org
      };

      group.groupChildren = [];
      org.groups.forEach((g) => {
        if (g.parent_id === grp.id) {
          group.groupChildren.push(nextGroup(org, g, group));
        }
      });

      group.networkChildren = [];
      org.networks.forEach((n) => {
        if (n.group_id === grp.id) {
          group.networkChildren.push({
            ...n,
            parent: group,
            itemType: 'network',
            org
          });
        }
      });

      return group;
    };

    const data = [];
    orgs.forEach((o) => {
      const org = { ...o, itemType: 'org' };
      delete org.groups;
      delete org.networks;

      org.groupChildren = [];
      o.groups.forEach((g) => {
        if (g.parent_id === null) {
          org.groupChildren.push(nextGroup(o, g, org));
        }
      });

      org.networkChildren = [];
      o.networks.forEach((n) => {
        if (n.group_id === null) {
          org.networkChildren.push({
            ...n,
            itemType: 'network',
            parent: org,
            org
          });
        }
      });

      data.push(org);
    });

    // console.log('data', data);
    return data;
  }, [orgs]);

  useEffect(() => {
    const { org_id, group_id, network_id } = location;
    const org = orgs.find((o) => o.id === org_id);
    if (org) {
      let path = [];
      if (network_id) {
        const network = org.networks.find((n) => n.id === network_id);
        if (network) {
          path.unshift(network.name);
          for (let gid = network.group_id; gid != null; ) {
            const group = org.groups.find((g) => g.id === gid);
            if (group) {
              path.unshift(group.name);
              gid = group.parent_id;
            } else {
              break;
            }
          }
          path.unshift(org.name);
        }
      } else if (group_id) {
        const group = org.groups.find((g) => g.id === group_id);
        if (group) {
          path.unshift(group.name);
          for (let gid = group.parent_id; gid != null; ) {
            const group = org.groups.find((g) => g.id === gid);
            if (group) {
              path.unshift(group.name);
              gid = group.parent_id;
            } else {
              break;
            }
          }
          path.unshift(org.name);
        }
      } else {
        path = [org.name];
      }
      setLocationText(path);
    }
  }, [orgs, location]);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    if (!isDialogOpen) setOpen(false);
  };

  const renderTree = useCallback(
    (items) => {
      const renderItem = (node) => {
        if (node.itemType === 'network') {
          return (
            <TreeItem
              onMouseOver={(event) => {
                event.stopPropagation();
                // console.log('onMouseOver', node.id, node.name);
                setHoveringId(node.id);
              }}
              onMouseLeave={() => {
                // console.log('onMouseLeave', node.id, node.name);
                setHoveringId((pre) => (pre === node.id ? null : pre));
              }}
              icon={<AccountTree color="inherit" />}
              key={node.id}
              nodeId={node.id}
              label={
                <Grid container spacing={0.1} direction="row" alignItems="center">
                  <Grid item xs="auto">
                    <TreeLabelRootStyles>
                      {(hoveringId === node.id && (
                        <Link
                          variant="body2"
                          onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            setSelected([node.id]);
                            dispatch(setLocation({ org_id: node.org.id, network_id: node.id }));
                            setOpen(false);
                          }}
                        >
                          {node.name}
                        </Link>
                      )) || <Typography variant="body2">{node.name}</Typography>}
                    </TreeLabelRootStyles>
                  </Grid>
                  {hoveringId === node.id && (
                    <Grid container item xs justifyContent="flex-end" alignItems="center">
                      <Tooltip title="Edit">
                        <IconButton
                          edge="end"
                          onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            dispatch(
                              dialogOpen(
                                'modifyNetwork',
                                'Edit Network',
                                {
                                  name: node.name,
                                  country_code: node.country_code,
                                  timezone: node.timezone
                                },
                                (data) => dispatch(modifyNetwork(node.org.id, node.id, data))
                              )
                            );
                          }}
                        >
                          <Icon icon={Edit} width={20} height={20} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Remove">
                        <IconButton
                          edge="end"
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            dispatch(
                              dialogOpen(
                                'confirmMessage',
                                'Confirm',
                                {
                                  message: `Are you sure you want to remove network '${node.name}'?`
                                },
                                (data) => dispatch(delNetwork(node.org.id, node.id))
                              )
                            );
                          }}
                        >
                          <Icon icon={Trash} width={20} height={20} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              }
              // classes={{
              //   root: TreeRootStyles,
              //   content: TreeContentStyles,
              //   expanded: {},
              //   selected: {},
              //   group: TreeGroupStyles,
              //   label: TreeLabelStyles
              // }}
            />
          );
        }
        return (
          <TreeItem
            onMouseOver={(event) => {
              event.stopPropagation();
              // console.log('onMouseOver', node.id, node.name);
              setHoveringId(node.id);
              // setHoveringId((pre) => (pre === null ? node.id : pre));
            }}
            onMouseLeave={() => {
              // console.log('onMouseLeave', node.id, node.name);
              setHoveringId((pre) => (pre === node.id ? null : pre));
            }}
            key={node.id}
            nodeId={node.id}
            label={
              <Grid container spacing={0.1} direction="row" alignItems="center">
                {(node.itemType === 'org' && <Business color="inherit" />) || (
                  <Folder color="inherit" />
                )}
                <Grid item xs="auto">
                  <TreeLabelRootStyles>
                    {(hoveringId === node.id && (
                      <Link
                        variant="body2"
                        onClick={(event) => {
                          // setActiveItemId(item.id);
                          // if you want after click do expand/collapse comment this two line
                          event.stopPropagation();
                          event.preventDefault();
                          setSelected([node.id]);
                          if (node.itemType === 'org') {
                            dispatch(setLocation({ org_id: node.id }));
                          } else {
                            dispatch(setLocation({ org_id: node.org.id, group_id: node.id }));
                          }
                          setOpen(false);
                        }}
                      >
                        {node.name}
                      </Link>
                    )) || <Typography variant="body2">{node.name}</Typography>}
                  </TreeLabelRootStyles>
                </Grid>
                {hoveringId === node.id && (
                  <Grid container item xs justifyContent="flex-end" alignItems="center">
                    <Tooltip title="New Network">
                      <IconButton
                        edge="end"
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          if (!expanded.find((id) => id === node.id)) {
                            setExpanded(expanded.concat([node.id]));
                          }
                          dispatch(
                            dialogOpen(
                              'addNetwork',
                              'Add Network',
                              {
                                name: '',
                                country_code: node.country_code || node.org.country_code,
                                timezone: node.timezone || node.org.timezone
                              },
                              (data) =>
                                dispatch(
                                  addNetwork(node?.org?.id || node.id, {
                                    ...data,
                                    group_id: node.itemType === 'org' ? null : node.id
                                  })
                                )
                            )
                          );
                        }}
                      >
                        <AccountTree sx={{ fontSize: 18 }} />
                        {/* <Icon icon={<AccountTree />} width={20} height={20} /> */}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="New Group">
                      <IconButton
                        edge="end"
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          if (!expanded.find((id) => id === node.id)) {
                            setExpanded(expanded.concat([node.id]));
                          }
                          dispatch(
                            dialogOpen(
                              'addGroup',
                              'Add Group',
                              {
                                name: ''
                              },
                              (data) =>
                                dispatch(
                                  addGroup(node?.org?.id || node.id, {
                                    ...data,
                                    parent_id: node.itemType === 'org' ? null : node.id
                                  })
                                )
                            )
                          );
                        }}
                      >
                        <Icon icon={FolderAdd} width={20} height={20} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <IconButton
                        edge="end"
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          dispatch(
                            dialogOpen(
                              node.itemType === 'org' ? 'modifyOrg' : 'modifyGroup',
                              node.itemType === 'org' ? 'Edit Organization' : 'Edit Group',
                              {
                                name: node.name,
                                ...(node.itemType === 'org' && {
                                  country_code: node.country_code,
                                  timezone: node.timezone
                                })
                              },
                              (data) =>
                                node.itemType === 'org'
                                  ? dispatch(modifyOrg(node.id, data))
                                  : dispatch(modifyGroup(node.org.id, node.id, data))
                            )
                          );
                        }}
                      >
                        <Icon icon={Edit} width={20} height={20} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove">
                      <IconButton
                        edge="end"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          dispatch(
                            dialogOpen(
                              'confirmMessage',
                              'Confirm',
                              {
                                message: `Are you sure you want to remove ${
                                  node.itemType === 'org' ? 'organization' : 'group'
                                } '${node.name}'?`
                              },
                              (data) =>
                                node.itemType === 'org'
                                  ? dispatch(delOrg(node.id))
                                  : dispatch(delGroup(node.org.id, node.id))
                            )
                          );
                        }}
                      >
                        <Icon icon={Trash} width={20} height={20} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            }
            // classes={{
            //   root: TreeRootStyles,
            //   content: TreeContentStyles,
            //   expanded: {},
            //   selected: {},
            //   group: TreeGroupStyles,
            //   label: TreeLabelStyles
            // }}
          >
            {node.groupChildren.length > 0 && node.groupChildren.map((node) => renderItem(node))}
            {node.networkChildren.length > 0 &&
              node.networkChildren.map((node) => renderItem(node))}
          </TreeItem>
        );
      };
      return items.map((node) => renderItem(node));
    },
    [hoveringId]
  );

  const isLgUP = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  // const hiddenDown = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <Grid
          sx={{
            marginTop: 2,
            marginLeft: 2
          }}
        >
          {true && (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <IconButton
                onClick={handleOpen}
                sx={{
                  padding: 0,
                  width: 44,
                  height: 44,
                  color: 'text.primary'
                }}
              >
                <Icon icon={listFill} />
              </IconButton>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="path-breadcrumb"
                sx={{ marginLeft: 1 }}
              >
                {locationText.map((text, idx) => (
                  <Typography key={idx} variant="body1">
                    {text}
                  </Typography>
                ))}
              </Breadcrumbs>
            </Grid>
          )}
          <Slide
            direction="down"
            in={isOpen}
            mountOnEnter
            unmountOnExit
            sx={{
              top: isLgUP ? 75 : 60,
              left: isLgUP ? 30 : 70
            }}
          >
            <TreeRootStyles>
              <Grid container justifyContent="flex-end">
                <Tooltip title="New Organization">
                  <IconButton
                    onClick={() => {
                      dispatch(
                        dialogOpen(
                          'addOrg',
                          'Add Organization',
                          { name: '', country_code: user.country_code, timezone: user.timezone },
                          (data) => {
                            const { name, country_code, timezone } = data;
                            return dispatch(addOrg(name, country_code, timezone));
                          }
                        )
                      );
                    }}
                  >
                    <Icon icon={Plus} width={22} height={22} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
              >
                {renderTree(treeItems)}
              </TreeView>
            </TreeRootStyles>
          </Slide>
        </Grid>
      </ClickAwayListener>
    </>
  );
}
