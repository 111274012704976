import { createSlice } from '@reduxjs/toolkit';

const initialState = { scanned_aps: [] };

const sortCmp = (a, b) => {
  if (a.signal > b.signal) return -1;
  if (a.signal < b.signal) return 1;
  if (a.ssid > b.ssid) return -1;
  if (a.ssid < b.ssid) return 1;
  if (a.bssid > b.bssid) return -1;
  if (a.bssid < b.bssid) return 1;
  return 0;
};

const slice = createSlice({
  name: 'scan',
  initialState,
  reducers: {
    load(state, action) {
      const aps = [];
      action.payload.forEach((a) => {
        aps.push({ ...a });
      });
      aps.sort(sortCmp);
      return { scanned_aps: aps };
    }
  }
});

export const { load } = slice.actions;
export default slice.reducer;
