/* eslint-disable camelcase */
import {
  load,
  add,
  del,
  set,
  groupAdd,
  groupDel,
  groupSet,
  networkAdd,
  networkDel,
  networkSet
} from '../reducers/orgs';
import { updateNetworkName, removeNetwork } from '../reducers/devices';
import api from './api';

export const loadOrgs = () => (dispatch) =>
  api.call('getOrgs', {
    dispatch,
    onResp: (orgs) => {
      const ret = dispatch(load(orgs));
      console.log('ret load orgs =>', ret);
      return ret.payload;
    }
  });

export const addOrg = (name, country_code, timezone) => (dispatch) =>
  api.call('addOrg', {
    dispatch,
    data: {
      name,
      country_code,
      timezone
    },
    onResp: (org) => {
      dispatch(add(org));
    }
  });

export const delOrg = (org_id) => (dispatch) =>
  api.call('delOrg', {
    dispatch,
    urlParams: {
      org_id
    },
    onResp: () => {
      dispatch(del({ id: org_id }));
    }
  });

export const modifyOrg = (org_id, data) => (dispatch) =>
  api.call('modifyOrg', {
    dispatch,
    urlParams: {
      org_id
    },
    data,
    onResp: () => {
      dispatch(set({ id: org_id, ...data }));
    }
  });

export const addGroup = (org_id, data) => (dispatch) =>
  api.call('addGroup', {
    dispatch,
    urlParams: {
      org_id
    },
    data,
    onResp: (group) => {
      dispatch(groupAdd({ id: org_id, group }));
    }
  });

export const delGroup = (org_id, group_id) => (dispatch) =>
  api.call('delGroup', {
    dispatch,
    urlParams: {
      org_id,
      group_id
    },
    onResp: () => {
      dispatch(groupDel({ id: org_id, group: { id: group_id } }));
    }
  });

export const modifyGroup = (org_id, group_id, data) => (dispatch) =>
  api.call('modifyGroup', {
    dispatch,
    urlParams: {
      org_id,
      group_id
    },
    data,
    onResp: () => {
      dispatch(groupSet({ id: org_id, group: { id: group_id, ...data } }));
    }
  });

export const addNetwork = (org_id, data) => (dispatch) =>
  api.call('addNetwork', {
    dispatch,
    urlParams: {
      org_id
    },
    data,
    onResp: (network) => {
      dispatch(networkAdd({ id: org_id, network }));
    }
  });

export const delNetwork = (org_id, network_id) => (dispatch) =>
  api.call('delNetwork', {
    dispatch,
    urlParams: {
      org_id,
      network_id
    },
    onResp: () => {
      dispatch(networkDel({ id: org_id, network: { id: network_id } }));
      dispatch(removeNetwork({ network_id }));
    }
  });

export const modifyNetwork = (org_id, network_id, data) => (dispatch) =>
  api.call('modifyNetwork', {
    dispatch,
    urlParams: {
      org_id,
      network_id
    },
    data,
    onResp: (network) => {
      dispatch(networkSet({ id: org_id, network }));
      if (network.name) {
        dispatch(updateNetworkName({ network_id, name: network.name }));
      }
    }
  });
