import React from 'react';
import { useSelector } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { styled } from '@material-ui/core/styles';

const RootStyle = styled('div')(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff'
}));

export default function DashboardBackdrop() {
  const { open } = useSelector((state) => state.backdrop);

  return (
    <RootStyle>
      <Backdrop open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </RootStyle>
  );
}
