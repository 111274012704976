import { createSlice } from '@reduxjs/toolkit';
import { ZERO_ID } from './constant';

const initialState = [];

const orgCovert = (org) => ({
  ...org,
  groups:
    (!org.groups && []) ||
    org.groups.map((g) => ({
      ...g,
      parent_id: g.parent_id === ZERO_ID ? null : g.parent_id
    })),
  networks:
    (!org.networks && []) ||
    org.networks.map((n) => ({
      ...n,
      group_id: n.group_id === ZERO_ID ? null : n.group_id
    }))
});

const sortCmp = (a, b) => {
  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
  if (a.created_at && b.created_at) {
    const d1 = new Date(a.created_at);
    const d2 = new Date(b.created_at);
    if (d1.getTime() < d2.getTime()) return -1;
    if (d1.getTime() > d2.getTime()) return 1;
  }
  return 0;
};

const orgSort = (orgs) => {
  orgs.sort(sortCmp);
  orgs.forEach((o) => {
    o.groups.sort(sortCmp);
    o.networks.sort(sortCmp);
  });
  return orgs;
};

const slice = createSlice({
  name: 'orgs',
  initialState,
  reducers: {
    load(state, action) {
      const newOrgs = [];
      action.payload.forEach((o) => {
        newOrgs.push(orgCovert(o));
      });
      return orgSort(newOrgs);
    },
    add(state, action) {
      const { id } = action.payload;
      const idx = state.findIndex((o) => o.id === id);
      if (idx >= 0) {
        console.warn(`add an existent org ${id}!`);
        return state;
      }
      const newOrgs = Object.assign([], state);
      newOrgs.push(orgCovert(action.payload));
      return orgSort(newOrgs);
    },
    del(state, action) {
      const { id } = action.payload;
      const idx = state.findIndex((o) => o.id === id);
      if (idx >= 0) {
        const newOrgs = Object.assign([], state);
        return newOrgs.filter((o) => o.id !== id);
      }
      console.warn(`del a non-existent org ${id}!`);
      return state;
    },
    set(state, action) {
      const { id } = action.payload;
      const idx = state.findIndex((o) => o.id === id);
      if (idx >= 0) {
        const newOrgs = Object.assign([], state);
        newOrgs[idx] = {
          ...newOrgs[idx],
          ...action.payload
        };
        return orgSort(newOrgs);
      }
      console.warn(`set a non-existent org ${id}!`);
      return state;
    },
    groupAdd(state, action) {
      const { id, group } = action.payload;
      const idx = state.findIndex((o) => o.id === id);
      if (idx < 0) {
        console.warn(`add a group ${group.id} to non-existent org ${id}!`);
        return state;
      }

      const gIdx = state[idx].groups.findIndex((g) => g.id === group.id);
      if (gIdx >= 0) {
        console.warn(`add an existing group ${group.id}!`);
        return state;
      }

      state[idx].groups.push({
        ...group,
        parent_id: group.parent_id === ZERO_ID ? null : group.parent_id
      });
      state[idx].groups.sort(sortCmp);
    },
    groupDel(state, action) {
      const { id, group } = action.payload;
      const idx = state.findIndex((o) => o.id === id);
      if (idx < 0) {
        console.warn(`del a group ${group.id} from non-existent org ${id}!`);
        return state;
      }

      const gIdx = state[idx].groups.findIndex((g) => g.id === group.id);
      if (gIdx < 0) {
        console.warn(`del a non-existing group ${group.id}!`);
        return state;
      }

      state[idx].networks.forEach((n) => {
        if (n.group_id === group.id) {
          n.group_id = state[idx].groups[gIdx].parent_id;
        }
      });

      state[idx].groups.forEach((g) => {
        if (g.parent_id === group.id) {
          g.parent_id = state[idx].groups[gIdx].parent_id;
        }
      });

      state[idx].groups = state[idx].groups.filter((g) => g.id !== group.id);
    },
    groupSet(state, action) {
      const { id, group } = action.payload;
      const idx = state.findIndex((o) => o.id === id);
      if (idx < 0) {
        console.warn(`set a group ${group.id} from non-existent org ${id}!`);
        return state;
      }

      const gIdx = state[idx].groups.findIndex((g) => g.id === group.id);
      if (gIdx < 0) {
        console.warn(`set a non-existing group ${group.id}!`);
        return state;
      }

      state[idx].groups[gIdx] = {
        ...state[idx].groups[gIdx],
        ...group,
        ...(group.parent_id !== undefined && {
          parent_id: group.parent_id === ZERO_ID ? null : group.parent_id
        })
      };
      state[idx].groups.sort(sortCmp);
    },
    networkAdd(state, action) {
      const { id, network } = action.payload;
      const idx = state.findIndex((o) => o.id === id);
      if (idx < 0) {
        console.warn(`add a network ${network.id} to non-existent org ${id}!`);
        return state;
      }

      const nIdx = state[idx].networks.findIndex((n) => n.id === network.id);
      if (nIdx >= 0) {
        console.warn(`add an existing network ${network.id}!`);
        return state;
      }

      state[idx].networks.push({
        ...network,
        group_id: network.group_id === ZERO_ID ? null : network.group_id
      });
      state[idx].networks.sort(sortCmp);
    },
    networkDel(state, action) {
      const { id, network } = action.payload;
      const idx = state.findIndex((o) => o.id === id);
      if (idx < 0) {
        console.warn(`del a network ${network.id} from non-existent org ${id}!`);
        return state;
      }

      const nIdx = state[idx].networks.findIndex((n) => n.id === network.id);
      if (nIdx < 0) {
        console.warn(`del a non-existing network ${network.id}!`);
        return state;
      }

      state[idx].networks = state[idx].networks.filter((n) => n.id !== network.id);
    },
    networkSet(state, action) {
      const { id, network } = action.payload;
      const idx = state.findIndex((o) => o.id === id);
      if (idx < 0) {
        console.warn(`add a network ${network.id} to non-existent org ${id}!`);
        return state;
      }

      const nIdx = state[idx].networks.findIndex((n) => n.id === network.id);
      if (nIdx < 0) {
        console.warn(`del a non-existing network ${network.id}!`);
        return state;
      }

      state[idx].networks[nIdx] = {
        ...state[idx].networks[nIdx],
        ...network,
        ...(network.group_id !== undefined && {
          group_id: network.group_id === ZERO_ID ? null : network.group_id
        })
      };
      state[idx].networks.sort(sortCmp);
    }
  }
});

export const {
  load,
  add,
  del,
  set,
  groupAdd,
  groupDel,
  groupSet,
  networkAdd,
  networkDel,
  networkSet
} = slice.actions;
export default slice.reducer;
