import { useState, useEffect, useRef, useMemo } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Card, CircularProgress, Stack, Link, Container, Typography } from '@material-ui/core';
// layouts
import { useDispatch, useSelector } from 'react-redux';
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import AuthSocial from '../components/authentication/AuthSocial';
import { backdropShow, backdropClose } from '../actions/backdrop';
import { confirm } from '../actions/user';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Confirm() {
  const [action, setAction] = useState('');
  const [status, setStatus] = useState({ operation: '' });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const action = params.get('action');
    if (!token || token.length === 0 || !action || action.length === 0) {
      navigate('/login');
      return;
    }
    setAction(action);
    // switch (action) {
    //   case 'signup':
    //   case 'invite':
    //     setStatus('Verifying');
    //       break;
    //   case 'reset-password':
    //     setStatus('Reset you');
    //       break;
    // }
    if (action !== 'reset-password') {
      setStatus({ operation: 'checking' });
      dispatch(confirm(token))
        .then((res) => setStatus({ operation: 'done', ...res }))
        .catch((err) => setStatus({ operation: 'failed', message: err.message }));
    }
  }, []);

  return (
    <RootStyle title="Confirmation | Minimal-UI">
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          Get started
        </Link>
      </AuthLayout> */}

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          {status.operation === 'checking' && (
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          )}
          {status.operation === 'failed' && (
            <Stack sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                {status.message}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                {action === 'signup'
                  ? 'You may need to register this account again. '
                  : 'The inviter may need to invite you again. '}
                <Link
                  underline="none"
                  variant="subtitle2"
                  component={RouterLink}
                  to={action === 'signup' ? '/register' : '/login'}
                >
                  {action === 'signup' ? 'Go to Register' : 'Back to Login'}
                </Link>
              </Typography>
            </Stack>
          )}
          {status.operation === 'done' && action === 'signup' && (
            <Stack sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Your account has been activated
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                {'Thank you for joining us. '}
                <Link
                  underline="none"
                  variant="subtitle2"
                  component={RouterLink}
                  to={`/login?email=${status.email}`}
                >
                  Go to Login
                </Link>
              </Typography>
            </Stack>
          )}
          {status.operation === 'done' && action === 'invite' && (
            <Stack sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                You have accepted the invitation
              </Typography>
              {status.next_step === 'signin' && (
                <Typography sx={{ color: 'text.secondary' }}>
                  <Link
                    underline="none"
                    variant="subtitle2"
                    component={RouterLink}
                    to={`/login?email=${status.email}&org_id=${status.org_id}${
                      status.network_id ? status.network_id : ''
                    }`}
                  >
                    Go to Login
                  </Link>
                </Typography>
              )}
              {status.next_step === 'signup' && (
                <Typography sx={{ color: 'text.secondary' }}>
                  {`Please register the account of ${status.email} to access this ${
                    status.network_id ? 'Network' : 'Orgnization'
                  }. `}
                  <Link
                    underline="none"
                    variant="subtitle2"
                    component={RouterLink}
                    to={`/register?email=${status.email}&org_id=${status.org_id}${
                      status.network_id ? status.network_id : ''
                    }`}
                  >
                    Go to Register
                  </Link>
                </Typography>
              )}
            </Stack>
          )}
          {/* <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {result === 'ok' }
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>

         

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="register">
                Get started
              </Link>
            </Typography>
          </MHidden> */}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
