import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import searchFill from '@iconify/icons-eva/search-fill';
import refreshOutline from '@iconify/icons-eva/refresh-outline';
import fileRemoveFill from '@iconify/icons-eva/file-remove-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import AccountTree from '@material-ui/icons/AccountTreeOutlined';
import SystemUpdateAltOutlined from '@material-ui/icons/SystemUpdateAltOutlined';
// import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAltIcon';
// material
import { styled } from '@material-ui/core/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  Button,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment
} from '@material-ui/core';

import { removeMembers } from '../../../actions/member';

import { backdropShow, backdropClose } from '../../../actions/backdrop';
import { snackbarSuccess, snackbarError } from '../../../actions/snackbar';
import { dialogOpen } from '../../../actions/dialog';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
  columnGap: '1em'
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

MemberListToolbar.propTypes = {
  selectedIds: PropTypes.array,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function MemberListToolbar({
  members,
  selected,
  filterName,
  onFilterName,
  networkItems,
  clearSelected,
  myPermission
}) {
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state);

  const selectedMembers = useMemo(
    () => members.filter((d) => selected.find((id) => id === d.id)),
    [members, selected]
  );

  // console.log(orgs);
  // console.log(location);
  // console.log(networkItems);

  return (
    <RootStyle
      sx={{
        ...(selectedMembers.length > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {selectedMembers.length > 0 ? (
        <Typography component="div" variant="subtitle1">
          {selectedMembers.length} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="Name / Email"
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}

      {selectedMembers.length > 0 ? (
        <>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Icon icon={fileRemoveFill} />}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              dispatch(
                dialogOpen(
                  'confirmItems',
                  'Confirm',
                  {
                    message: `Are you sure you want to remove the following member${
                      selectedMembers.length > 1 ? 's' : ''
                    }?`,
                    items: selectedMembers.map(
                      (m) => `${m.name.length > 0 ? `${m.name} (${m.email})` : m.email}`
                    )
                  },
                  (data) => dispatch(removeMembers(location.org_id, myPermission, selectedMembers))
                )
              );
            }}
          >
            Remove
          </Button>
          {/* <Tooltip title="Remove">
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                dispatch(
                  dialogOpen(
                    'removeDevices',
                    'Remove Device',
                    {
                      message: `Are you sure you want to remove the following device${
                        selectedDevices.length > 1 ? 's' : ''
                      }?`,
                      items: selectedDevices.map((d) => `${d.name} (${d.mac})`)
                    },
                    (data) => dispatch(deregisterDevices(location.org_id, selectedDevices))
                  )
                );
              }}
            >
              <Icon icon={trash2Fill} />
            </IconButton>
          </Tooltip> */}
        </>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <Icon icon={roundFilterList} />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}
