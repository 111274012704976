/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import { ZERO_ID } from './constant';

const initialState = { devices: [], total: 0, reload: 0 };

const slice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    load(state, action) {
      const { total, devices } = action.payload;
      state.total = total;
      state.devices = [];
      devices.forEach((d) => {
        state.devices.push({
          ...d,
          org_id: d.org_id === ZERO_ID ? null : d.org_id,
          network_id: d.network_id === ZERO_ID ? null : d.network_id
        });
      });
    },
    // add(state, action) {
    //   action.payload.forEach((d) => {
    //     const idx = state.devices.findIndex((dev) => dev.id === id.id);
    //     if (idx >= 0) {
    //       console.warn(`add an existing device ${d.id}!`);
    //       continue;
    //     }
    //   });

    //   const idx = state.devices.findIndex((d) => d.id === id);
    //   if (idx >= 0) {
    //     console.warn('add an existing device!');
    //     return;
    //   }

    //   state.total += 1;
    //   state.devices.unshift({
    //     ...action.payload,
    //     org_id: org_id === ZERO_ID ? null : org_id,
    //     network_id: network_id === ZERO_ID ? null : network_id,
    //     network_name: network_name || ''
    //   });
    // },
    // del(state, action) {
    //   const { id } = action.payload;
    //   const idx = state.devices.findIndex((d) => d.id === id);
    //   if (idx < 0) {
    //     console.warn('del a non-existent device!');
    //     return;
    //   }

    //   state.total -= 1;
    //   state.devices = state.devices.filter((d) => d.id !== id);
    // },
    set(state, action) {
      const { id, mac, org_id, network_id, connection } = action.payload;
      let idx = -1;
      if (id) {
        idx = state.devices.findIndex((d) => d.id === id);
      } else if (mac) {
        idx = state.devices.findIndex((d) => d.mac === mac);
      }
      if (idx >= 0) {
        state.devices[idx] = {
          ...state.devices[idx],
          ...action.payload,
          ...(connection !== undefined && {
            connection: {
              ...state.devices[idx].connection,
              ...connection
            }
          }),
          ...(network_id !== undefined && {
            network_id: network_id === ZERO_ID ? null : network_id
          }),
          ...(org_id !== undefined && {
            org_id: org_id === ZERO_ID ? null : org_id
          })
        };
      } else {
        console.warn('set a non-existent device!');
      }
    },
    updateNetworkName(state, action) {
      const { network_id, name } = action.payload;
      state.devices.forEach((d) => {
        if (d.network_id === network_id) {
          d.network_name = name;
        }
      });
    },
    removeNetwork(state, action) {
      const { network_id } = action.payload;
      state.devices.forEach((d) => {
        if (d.network_id === network_id) {
          d.network_name = '';
          d.network_id = ZERO_ID;
          d.connection.online = false;
          d.connection.update_at = null;
        }
      });
    },
    triggerReload(state, action) {
      state.reload += 1;
    }
  }
});

export const { load, add, del, set, updateNetworkName, removeNetwork, triggerReload } =
  slice.actions;
export default slice.reducer;
