import { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { LoadingButton } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Stack, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { snackbarSuccess, snackbarError } from '../../actions/snackbar';
import { getModelsFirmware } from '../../actions/firmwares';
import { backdropShow, backdropClose } from '../../actions/backdrop';
import { dialogClose, dialogSetData } from '../../actions/dialog';
import { ChannelList } from '../../utils/channel';

const ct = require('countries-and-timezones');

const SerialNumberTextarea = styled(TextareaAutosize)({
  // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  // border: 1,
  borderRadius: 6,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  // color: 'white',
  width: 280,
  // minHeight: 36,
  fontSize: '16px',
  padding: '5px 15px'
});

// const NetworkSelect = styled(TextField)({
//   minWidth: 200
// });

const ItemSpot = styled('span')(({ theme }) => ({
  background: 'black',
  marginRight: '6px',
  marginBottom: '2px',
  height: '6px',
  width: '6px',
  display: 'inline-block'
}));

const ItemWording = styled('span')(({ theme }) => ({
  ...theme.typography,
  ...theme.typography.body2
}));

export default function DashboardDialog() {
  // const { title, open, setOpen, defName, defCountryCode, defTimeZone, onApply } = props;
  // const [name, setName] = useState(defName);
  // const [countryCode, setCountryCode] = useState(defCountryCode);
  // const [timezone, setTimeZone] = useState(defTimeZone);
  // const [isLoading, setIsLoading] = useState(false);
  const [isApplying, setIsApplying] = useState(false);
  const { open, title, type, data, onApply } = useSelector((state) => state.dialog);
  const { firmwares } = useSelector((state) => state.firmwares);
  const dispatch = useDispatch();

  const CountryMenuItems = useMemo(() => {
    const countries = ct.getAllCountries();
    const cmp = (a, b) => {
      if (a[1].name < b[1].name) return -1;
      if (a[1].name > b[1].name) return 1;
      return 0;
    };
    return Object.entries(countries)
      .sort(cmp)
      .map(([key, value]) => (
        <MenuItem key={key} value={key}>
          {value.name}
        </MenuItem>
      ));
  }, []);

  const timeZoneMenuItems = useMemo(() => {
    const timeZones = ct.getAllTimezones();
    const cmp = (a, b) => {
      if (a[1].utcOffset < b[1].utcOffset) return -1;
      if (a[1].utcOffset > b[1].utcOffset) return 1;
      return 0;
    };
    return Object.entries(timeZones)
      .filter(([key, value]) => value.aliasOf === null)
      .sort(cmp)
      .map(([key, value]) => (
        <MenuItem key={key} value={key}>{`(${value.utcOffsetStr}) ${value.name}`}</MenuItem>
      ));
  }, []);

  const channels = useMemo(() => {
    if (type !== 'configChannel') return [];
    const { band, selectedChannelWidth } = data;
    return ChannelList(band, selectedChannelWidth);
  }, [type, data]);

  useEffect(() => {
    if (type !== 'configChannel') return;
    const { selectedChannel } = data;
    if (selectedChannel !== 0 && !channels.find((c) => c.displayChannel === selectedChannel))
      dispatch(dialogSetData({ selectedChannel: 0 }));
  }, [channels, data]);

  // const modelFirmwaresMenuItems = useMemo(() => {
  //   if (!data?.models) return {};

  //   const itemsMap = {};
  //   data.models.forEach((m) => {
  //     if (firmwares[m] && firmwares[m].length > 0) {
  //       itemsMap[m] = firmwares[m].map((ver) => (
  //         <MenuItem key={`${m}-${ver}`} value={ver}>
  //           {ver}
  //         </MenuItem>
  //       ));
  //     } else {
  //       itemsMap[m] = null;
  //     }
  //   });
  //   return itemsMap;
  // }, [firmwares, data]);

  // const NetworkMenuItems = useMemo(() => {
  //   if (!data.networkItems) {
  //     return null;
  //   }
  //   return data.networkItems.map((item) => (
  //     <MenuItem key={item.network_id} value={item.network_id}>
  //       {item.networkPath}
  //     </MenuItem>
  //   ));
  // }, [data.networkItems]);

  const dialogContent = useMemo(() => {
    switch (type) {
      case 'addOrg':
      case 'addGroup':
      case 'addNetwork':
      case 'modifyOrg':
      case 'modifyGroup':
      case 'modifyNetwork':
        return (
          <Stack spacing={3}>
            <TextField
              required
              autoFocus
              id="name"
              label="Name"
              margin="normal"
              onChange={(event) => dispatch(dialogSetData({ name: event.target.value }))}
              value={data.name}
              fullWidth
              disabled={isApplying}
            />
            {type !== 'addGroup' && type !== 'modifyGroup' && (
              <>
                <TextField
                  select
                  id="country-select"
                  label="Country"
                  value={data.country_code}
                  variant="outlined"
                  margin="normal"
                  onChange={(event) => {
                    const country = ct.getCountry(event.target.value);
                    dispatch(
                      dialogSetData({
                        country_code: event.target.value,
                        timezone: country.timezones.find(
                          (tz) => ct.getTimezone(tz).aliasOf === null
                        )
                      })
                    );
                  }}
                  disabled={isApplying}
                >
                  {CountryMenuItems}
                </TextField>
                <TextField
                  select
                  id="timezone-select"
                  label="Time Zone"
                  value={data.timezone}
                  variant="outlined"
                  margin="normal"
                  onChange={(event) => dispatch(dialogSetData({ timezone: event.target.value }))}
                  disabled={isApplying}
                >
                  {timeZoneMenuItems}
                </TextField>
              </>
            )}
          </Stack>
        );
      case 'editDeviceNetwork':
        return (
          <Stack spacing={1}>
            <RadioGroup
              aria-label="gender"
              name="network-action-radio-buttons-group"
              value={data.action}
              onChange={(event) => dispatch(dialogSetData({ action: event.target.value }))}
            >
              <Stack direction={{ xs: 'row', sm: 'row' }} spacing={0}>
                <FormControlLabel
                  disabled={isApplying}
                  value="move"
                  control={<Radio />}
                  sx={{
                    minWidth: 110
                  }}
                  label="Move to"
                />
                <TextField
                  select
                  disabled={isApplying || data.action !== 'move'}
                  id="network-select"
                  label="Network"
                  value={data.selectedNetworkID}
                  variant="outlined"
                  margin="normal"
                  onChange={(event) =>
                    dispatch(dialogSetData({ selectedNetworkID: event.target.value }))
                  }
                  fullWidth
                >
                  {data.networkItems.map((item) => (
                    <MenuItem key={item.network_id} value={item.network_id}>
                      {item.path}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <FormControlLabel
                disabled={isApplying}
                value="remove"
                control={<Radio />}
                label="Remove from network"
              />
            </RadioGroup>
          </Stack>
        );
      case 'inviteMember':
        return (
          <Stack spacing={2}>
            <TextField
              required
              autoFocus
              id="invitee-email"
              type="email"
              label="Invitee's email address"
              margin="normal"
              onChange={(event) => dispatch(dialogSetData({ email: event.target.value }))}
              value={data.email}
              fullWidth
              disabled={isApplying}
            />
            <RadioGroup
              aria-label="gender"
              name="perm-type-radio-buttons-group"
              value={data.permType}
              onChange={(event) => dispatch(dialogSetData({ permType: event.target.value }))}
            >
              <Stack direction={{ xs: 'column', sm: 'column' }} spacing={0}>
                <FormControlLabel
                  disabled={isApplying || !data.orgPermAllowed}
                  id="org-perm-radio"
                  value="org"
                  control={<Radio />}
                  label={`Grant access to orgnization '${data.orgName}'`}
                />
                <Stack direction={{ xs: 'row', sm: 'row' }} spacing={0}>
                  <FormControlLabel
                    disabled={isApplying || data.networkItems.length === 0}
                    id="network-perm-radio"
                    value="network"
                    control={<Radio />}
                    sx={{
                      minWidth: 158
                    }}
                    label="Grant access to"
                  />
                  <TextField
                    select
                    disabled={
                      isApplying || data.permType !== 'network' || data.networkItems.length === 0
                    }
                    id="network-perm-select"
                    label="Network"
                    value={data.networkID}
                    variant="outlined"
                    margin="normal"
                    onChange={(event) => dispatch(dialogSetData({ networkID: event.target.value }))}
                    fullWidth
                  >
                    {data.networkItems.map((item) => (
                      <MenuItem key={item.network_id} value={item.network_id}>
                        {item.path}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
              </Stack>
            </RadioGroup>
            <TextField
              select
              disabled={isApplying}
              id="perm-select"
              label="Permission"
              value={data.permission}
              variant="outlined"
              margin="normal"
              onChange={(event) => dispatch(dialogSetData({ permission: event.target.value }))}
            >
              <MenuItem key="admin-perm" value="admin">
                Admin
              </MenuItem>
              <MenuItem key="viewer-perm" value="viewer">
                Viewer
              </MenuItem>
            </TextField>
          </Stack>
        );
      case 'confirmMessage':
        return <DialogContentText>{data.message}</DialogContentText>;
      case 'modifyDeviceName':
        return (
          <TextField
            required
            autoFocus
            id="name"
            label="Name"
            margin="normal"
            onChange={(event) => dispatch(dialogSetData({ name: event.target.value }))}
            value={data.name}
            fullWidth
            disabled={isApplying}
          />
        );
      case 'confirmItems':
        return (
          <Stack spacing={0.5}>
            <DialogContentText>{data.message}</DialogContentText>
            {data.items.map((s, idx) => (
              <div key={`confirm-item${idx}`}>
                <ItemSpot />
                <Typography component="span" variant="body2">
                  {s}
                </Typography>
                {/* <ItemWording>{s}</ItemWording> */}
              </div>
            ))}
          </Stack>
        );
      case 'registerDevices':
        return (
          <Stack spacing={0.5}>
            <DialogContentText>Enter the serial number one per line</DialogContentText>
            {/* <SerialNumberTextarea
              minRows={8}
              aria-label="empty textarea"
              value={data.text}
              onKeyDown={(e) => {
                const found = e.key.match(/[A-Za-z0-9\n]/g);
                // console.log(found);
                if (!found) {
                  e.preventDefault();
                }
              }}
              onChange={(event) => {
                dispatch(
                  dialogSetData({
                    text: event.target.value.replace(/\n\n/g, '\n').toUpperCase()
                  })
                );
              }}
            /> */}
            <TextField
              multiline
              disabled={isApplying}
              minRows={8}
              aria-label="empty textarea"
              value={data.text}
              onKeyDown={(e) => {
                const found = e.key.match(/[A-Za-z0-9\n]/g);
                // console.log(found);
                if (!found) {
                  e.preventDefault();
                }
              }}
              onChange={(event) => {
                dispatch(
                  dialogSetData({
                    text: event.target.value.replace(/\n\n/g, '\n').toUpperCase()
                  })
                );
              }}
            />
          </Stack>
        );
      case 'upgradeDevicesFirmware':
        return (
          <Stack spacing={2}>
            {Object.entries(data.modelFirmwares).map(([model, versions]) => (
              <TextField
                select
                id={`${model}-firmware-select`}
                label={model}
                value={data.selectedVersions[model]}
                variant="outlined"
                margin="normal"
                onChange={(event) =>
                  dispatch(
                    dialogSetData({
                      selectedVersions: {
                        ...data.selectedVersions,
                        [model]: event.target.value
                      }
                    })
                  )
                }
                disabled={isApplying}
              >
                {versions.map((v) => (
                  <MenuItem key={`${model}-${v}`} value={v}>
                    {v}
                  </MenuItem>
                ))}
              </TextField>
            ))}
          </Stack>
        );
      case 'configChannel':
        return (
          <Stack spacing={2}>
            <TextField
              select
              id="channel-select"
              label="Channel"
              value={data.selectedChannel}
              variant="outlined"
              margin="normal"
              onChange={(event) =>
                dispatch(
                  dialogSetData({
                    selectedChannel: event.target.value
                  })
                )
              }
              disabled={isApplying}
            >
              <MenuItem key="ch0" value={0}>
                Auto
              </MenuItem>
              {channels.map((c) => (
                <MenuItem key={`ch${c.channel}`} value={c.displayChannel}>
                  {`${c.displayChannel} (${c.centerFreq} MHz)`}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              id="channel-width-select"
              label="Width"
              value={data.selectedChannelWidth}
              variant="outlined"
              margin="normal"
              onChange={(event) =>
                dispatch(
                  dialogSetData({
                    selectedChannelWidth: event.target.value
                  })
                )
              }
              disabled={isApplying}
            >
              <MenuItem key="w20" value={20}>
                20 MHz
              </MenuItem>
              <MenuItem key="w40" value={40}>
                40 MHz
              </MenuItem>
              {data.band !== 'band_2g' && (
                <MenuItem key="w80" value={80}>
                  80 MHz
                </MenuItem>
              )}
            </TextField>
          </Stack>
        );
      default:
        return null;
    }
  }, [type, data, isApplying]);

  const buttons = useMemo(() => {
    switch (type) {
      case 'confirmMessage':
      case 'confirmItems':
        return {
          apply: {
            color: 'error',
            text: 'Yes'
          },
          cancel: {
            color: 'primary',
            text: 'No'
          }
        };
      default:
        return {
          apply: {
            color: 'primary',
            text: 'Apply'
          },
          cancel: {
            color: 'primary',
            text: 'Cancel'
          }
        };
    }
  }, [type]);

  const isFullWidth = useMemo(() => {
    switch (type) {
      case 'confirmMessage':
      case 'registerDevices':
      case 'confirmItems':
        return false;
      default:
        return true;
    }
  }, [type]);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    dispatch(dialogClose());
    setIsApplying(false);
  }, []);

  const handleApply = useCallback(() => {
    setIsApplying(true);
    return onApply(data)
      .then(() => {
        dispatch(snackbarSuccess('Success'));
        dispatch(dialogClose());
      })
      .catch((err) => {
        console.log('err.message', err.message);
        dispatch(snackbarError(err.message));
        if (type === 'registerDevices') {
          const serialNumbers = err.message.split('\n').map((m) => m.split(':')[0]);
          let failSNText = '';
          serialNumbers.forEach((sn) => {
            if (failSNText.length > 0) failSNText += '\n';
            failSNText += sn;
          });
          dispatch(
            dialogSetData({
              text: failSNText
            })
          );
        }
      })
      .finally(() => setIsApplying(false));
  }, [data, type, onApply]);

  return (
    <Dialog
      fullWidth={isFullWidth}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isApplying} color={buttons.cancel.color}>
          {buttons.cancel.text}
        </Button>
        <LoadingButton onClick={handleApply} loading={isApplying} color={buttons.apply.color}>
          {buttons.apply.text}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export function DelDialog(props) {}
