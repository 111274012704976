/* eslint-disable camelcase */
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import plusFill from '@iconify/icons-eva/plus-fill';
import fileAdd from '@iconify/icons-eva/file-add-fill';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import {
  Link,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Backdrop
} from '@material-ui/core';
import api from '../actions/api';

import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import { DeviceListToolbar, DeviceMoreMenu } from '../components/_dashboard/device';

import { loadDevices, registerDevices } from '../actions/device';
import { snackbarError } from '../actions/snackbar';
import { backdropShow, backdropClose } from '../actions/backdrop';
import { dialogOpen } from '../actions/dialog';

const TABLE_HEAD = [
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'serial_number', label: 'Serial Number', alignRight: false },
  { id: 'mac', label: 'MAC', alignRight: false },
  { id: 'model', label: 'Model', alignRight: false },
  { id: 'network', label: 'Network', alignRight: false },
  { id: '' }
];

export default function Inventory() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { devices, total, reload } = useSelector((state) => state.devices);
  const location = useSelector((state) => state.location);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location) {
      dispatch(backdropShow());
      dispatch(loadDevices({ org_id: location.org_id }))
        .then(() => setSelected([]))
        .catch((err) => dispatch(snackbarError(err.message)))
        .finally(() => dispatch(backdropClose()));
    }
  }, [location, reload]);

  const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === 'asc';
    // setOrder(isAsc ? 'desc' : 'asc');
    // setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = devices.map((d) => d.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - devices.length) : 0;

  // const filteredUsers = applySortFilter(devices, getComparator(order, orderBy), filterName);

  // const isUserNotFound = filteredUsers.length === 0;

  // console.log('selected', selected);

  return (
    <Page title="Inventory | Minimal-UI">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Inventory
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={fileAdd} />}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              dispatch(
                dialogOpen('registerDevices', 'Register Device', { text: '' }, (data) => {
                  const { text } = data;
                  const serialNumbers = text.split('\n').filter((s) => s.length > 0);
                  return dispatch(registerDevices(location.org_id, serialNumbers));
                })
              );
            }}
          >
            Register Device
          </Button>
        </Stack>

        <Card>
          <DeviceListToolbar
            devices={devices}
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 900 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={devices.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {devices.map((row) => {
                    const {
                      type,
                      id,
                      org_id,
                      network_id,
                      name,
                      serial_number,
                      model,
                      network_name,
                      mac,
                      connection
                    } = row;
                    const { online, wan_ip, update_at } = connection;

                    const isItemSelected = selected.indexOf(id) >= 0;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, id)}
                          />
                        </TableCell>
                        {/* <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell> */}
                        <TableCell align="left">{(type === 'ap' && 'AP') || '?'}</TableCell>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{serial_number}</TableCell>
                        <TableCell align="left">{mac}</TableCell>
                        <TableCell align="left">{model}</TableCell>
                        <TableCell align="left">{network_name}</TableCell>
                        <TableCell align="right">
                          <DeviceMoreMenu location={location} device={row} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {/* {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
