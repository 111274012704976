/*
1,2412,2401–2423,Yes,Yes,Yes,Yes
2,2417,2406–2428,Yes,Yes,Yes,Yes
3,2422,2411–2433,Yes,Yes,Yes,Yes
4,2427,2416–2438,Yes,Yes,Yes,Yes
5,2432,2421–2443,Yes,Yes,Yes,Yes
6,2437,2426–2448,Yes,Yes,Yes,Yes
7,2442,2431–2453,Yes,Yes,Yes,Yes
8,2447,2436–2458,Yes,Yes,Yes,Yes
9,2452,2441–2463,Yes,Yes,Yes,Yes
10,2457,2446–2468,Yes,Yes,Yes,Yes
11,2462,2451–2473,Yes,Yes,Yes,Yes
12,2467,2456–2478,NoB,Yes,Yes,Yes
13,2472,2461–2483,NoB,Yes,Yes,Yes
14,2484,2473–2495,No,11b onlyC,No,No
36,5180,5170–5190,,20,,,,U-NII-1,Yes,Indoors,Indoors/TPC or [note 1] [note 2],Indoors/TPC or [note 1] [note 2],Indoors/TPC or [note 3],Indoors,Yes,Yes,Yes,Indoors,Indoors,Indoors,Indoors,"Indoors/DFS/TPC",Indoors,Yes,Indoors,Indoors,Indoors,Indoors
38,5190,5170–5210,,,40,,,U-NII-1,Yes,Indoors,Indoors/TPC or [note 1] [note 2],Indoors/TPC or [note 1] [note 2],Indoors/TPC or [note 3],Indoors,Yes,Yes,Yes,Indoors,Indoors,Indoors,Indoors,"Indoors/DFS/TPC",Indoors,Yes,Indoors,Indoors,Indoors,Indoors
40,5200,5190–5210,,20,,,,U-NII-1,Yes,Indoors,Indoors/TPC or [note 1] [note 2],Indoors/TPC or [note 1] [note 2],Indoors/TPC or [note 3],Indoors,Yes,Yes,Yes,Indoors,Indoors,Indoors,Indoors,"Indoors/DFS/TPC",Indoors,Yes,Indoors,Indoors,Indoors,Indoors
42,5210,5170–5250,,,,80,,U-NII-1,Yes,Indoors,Indoors/TPC or [note 1] [note 2],Indoors/TPC or [note 1] [note 2],Indoors/TPC or [note 3],Indoors,Yes,Yes,Indoors,Indoors,Indoors,Indoors,Indoors,No,No,Yes,Indoors,Indoors,Indoors,Indoors
44,5220,5210–5230,,20,,,,U-NII-1,Yes,Indoors,Indoors/TPC or [note 1] [note 2],Indoors/TPC or [note 1] [note 2],Indoors/TPC or [note 3],Indoors,Yes,Yes,Yes,Indoors,Indoors,Indoors,Indoors,"Indoors/DFS/TPC",Indoors,Yes,Indoors,Indoors,Indoors,Indoors
46,5230,5210–5250,,,40,,,U-NII-1,Yes,Indoors,Indoors/TPC or [note 1] [note 2],Indoors/TPC or [note 1] [note 2],Indoors/TPC or [note 3],Indoors,Yes,Yes,Yes,Indoors,Indoors and [note 4],Indoors,Indoors,"Indoors/DFS/TPC",Indoors,Yes,Indoors,Indoors,Indoors,Indoors
48,5240,5230–5250,,20,,,,U-NII-1,Yes,Indoors,Indoors/TPC or [note 1] [note 2],Indoors/TPC or [note 1] [note 2],Indoors/TPC or [note 3],Indoors,Yes,Yes,Yes,Indoors,Indoors and [note 4],Indoors,Indoors,"Indoors/DFS/TPC",Indoors,Yes,Indoors,Indoors,Indoors,Indoors
50,5250,5170–5330,,,,,160,U-NII-1 and U-NII-2A,DFS/TPC or [note 5],DFS/TPC or [note 5],"Indoors/DFS/TPC or [note 1] [note 2]","Indoors/DFS/TPC or [note 1] [note 2]",Indoors/TPC or [note 3],"Indoors/DFS/TPC",DFS/TPC or [note 6],DFS/TPC or [note 3],No,"Indoors/DFS/TPC or[note 3]","Indoors/DFS/TPC","Indoors/DFS/TPC","Indoors/DFS/TPC or[note 3]",No,No,DFS,DFS/TPC or [note 3],"Indoors/DFS/TPC or[note 3]",DFS/TPC,No
52,5260,5250–5270,,20,,,,U-NII-2A,DFS/TPC or [note 5],DFS/TPC or [note 5],"Indoors/DFS/TPC or [note 1] [note 2]","Indoors/DFS/TPC or [note 1] [note 2]",Indoors/TPC or [note 3],"Indoors/DFS/TPC",Indoors,DFS/TPC or [note 3],DFS/TPC,"Indoors/DFS/TPC or[note 3]",DFS/TPC,"Indoors/DFS/TPC","Indoors/DFS/TPC or[note 3]","Indoors/DFS/TPC",Indoors,DFS,DFS/TPC or [note 3],"Indoors/DFS/TPC or[note 3]",DFS/TPC,Indoors
54,5270,5250–5290,,,40,,,U-NII-2A,DFS/TPC or [note 5],DFS/TPC or [note 5],"Indoors/DFS/TPC or [note 1] [note 2]","Indoors/DFS/TPC or [note 1] [note 2]",Indoors/TPC or [note 3],"Indoors/DFS/TPC",Indoors,DFS/TPC or [note 3],DFS/TPC,"Indoors/DFS/TPC or[note 3]",DFS/TPC,"Indoors/DFS/TPC","Indoors/DFS/TPC or[note 3]","Indoors/DFS/TPC",Indoors,DFS,DFS/TPC or [note 3],"Indoors/DFS/TPC or[note 3]",DFS/TPC,Indoors
56,5280,5270–5290,,20,,,,U-NII-2A,DFS/TPC or [note 5],DFS/TPC or [note 5],"Indoors/DFS/TPC or [note 1] [note 2]","Indoors/DFS/TPC or [note 1] [note 2]",Indoors/TPC or [note 3],"Indoors/DFS/TPC",Indoors,DFS/TPC or [note 3],DFS/TPC,"Indoors/DFS/TPC or[note 3]",DFS/TPC,"Indoors/DFS/TPC","Indoors/DFS/TPC or[note 3]","Indoors/DFS/TPC",Indoors,DFS,DFS/TPC or [note 3],"Indoors/DFS/TPC or[note 3]",DFS/TPC,Indoors
58,5290,5250–5330,,,,80,,U-NII-2A,DFS/TPC or [note 5],DFS/TPC or [note 5],"Indoors/DFS/TPC or [note 1] [note 2]","Indoors/DFS/TPC or [note 1] [note 2]",Indoors/TPC or [note 3],"Indoors/DFS/TPC",Indoors,DFS/TPC or [note 3],"Indoors/DFS/TPC","Indoors/DFS/TPC or[note 3]",DFS/TPC,"Indoors/DFS/TPC","Indoors/DFS/TPC or[note 3]",No,No,DFS,DFS/TPC or [note 3],"Indoors/DFS/TPC or[note 3]",DFS/TPC,Indoors
60,5300,5290–5310,,20,,,,U-NII-2A,DFS/TPC or [note 5],DFS/TPC or [note 5],"Indoors/DFS/TPC or [note 1] [note 2]","Indoors/DFS/TPC or [note 1] [note 2]",Indoors/TPC or [note 3],"Indoors/DFS/TPC",Indoors,DFS/TPC or [note 3],DFS/TPC,"Indoors/DFS/TPC or[note 3]",DFS/TPC,"Indoors/DFS/TPC","Indoors/DFS/TPC or[note 3]","Indoors/DFS/TPC",Indoors,DFS,DFS/TPC or [note 3],"Indoors/DFS/TPC or[note 3]",DFS/TPC,Indoors
62,5310,5290–5330,,,40,,,U-NII-2A,DFS/TPC or [note 5],DFS/TPC or [note 5],"Indoors/DFS/TPC or [note 1] [note 2]","Indoors/DFS/TPC or [note 1] [note 2]",Indoors/TPC or [note 3],"Indoors/DFS/TPC",Indoors,DFS/TPC or [note 3],DFS/TPC,"Indoors/DFS/TPC or[note 3]",DFS/TPC,"Indoors/DFS/TPC","Indoors/DFS/TPC or[note 3]","Indoors/DFS/TPC",Indoors,DFS,DFS/TPC or [note 3],"Indoors/DFS/TPC or[note 3]",DFS/TPC,Indoors
64,5320,5310–5330,,20,,,,U-NII-2A,DFS/TPC or [note 5],DFS/TPC or [note 5],"Indoors/DFS/TPC or [note 1] [note 2]","Indoors/DFS/TPC or [note 1] [note 2]",Indoors/TPC or [note 3],"Indoors/DFS/TPC",Indoors,DFS/TPC or [note 3],DFS/TPC,"Indoors/DFS/TPC or[note 3]",DFS/TPC,"Indoors/DFS/TPC","Indoors/DFS/TPC or[note 3]","Indoors/DFS/TPC",Indoors,DFS,DFS/TPC or [note 3],"Indoors/DFS/TPC or[note 3]",DFS/TPC,Indoors
68,5340,5330–5350,,20,,,,U-NII-2A,DFS/TPC or [note 5],DFS/TPC or [note 5],"Indoors/DFS/TPC or [note 1] [note 2]","Indoors/DFS/TPC or [note 1] [note 2]",Indoors/TPC or [note 3],"Indoors/DFS/TPC",Yes,DFS/TPC or [note 3],Un­known,"Indoors/DFS/TPC or[note 3]",DFS/TPC,"Indoors/DFS/TPC","Indoors/DFS/TPC or[note 3]",Un­known,Un­known,DFS,DFS/TPC or [note 3],"Indoors/DFS/TPC or[note 3]",DFS/TPC,Indoors
96,5480,5470–5490,,20,,,,U-NII-2C,DFS/TPC or [note 5],DFS/TPC or [note 5],"Indoors/DFS/TPC or [note 7] [note 8]",DFS/TPC or [note 7] [note 8],No,DFS/TPC,Yes,DFS/TPC,Un­known,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,DFS/TPC or [note 5],Un­known,Un­known,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
100,5500,5490–5510,,20,,,,U-NII-2C,DFS/TPC or [note 5],DFS/TPC or [note 5],DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],No,DFS/TPC,Yes,DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,DFS/TPC or [note 5],Yes,DFS,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
102,5510,5490–5530,,,40,,,U-NII-2C,DFS/TPC or [note 5],DFS/TPC or [note 5],DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],No,DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,DFS/TPC or [note 5],Yes,DFS,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
104,5520,5510–5530,,20,,,,U-NII-2C,DFS/TPC or [note 5],DFS/TPC or [note 5],DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],No,DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,DFS/TPC or [note 5],Yes,DFS,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
106,5530,5490–5570,,,,80,,U-NII-2C,DFS/TPC or [note 5],DFS/TPC or [note 5],DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],No,DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,DFS/TPC or [note 5],No,No,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
108,5540,5530–5550,,20,,,,U-NII-2C,DFS/TPC or [note 5],DFS/TPC or [note 5],DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],No,DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,DFS/TPC or [note 5],Yes,DFS,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
110,5550,5530–5570,,,40,,,U-NII-2C,DFS/TPC or [note 5],DFS/TPC or [note 5],DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],No,DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,DFS/TPC or [note 5],Yes,DFS,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
112,5560,5550–5570,,20,,,,U-NII-2C,DFS/TPC or [note 5],DFS/TPC or [note 5],DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],No,DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,DFS/TPC or [note 5],Yes,DFS,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
114,5570,5490–5650,,,,,160,U-NII-2C,DFS/TPC or [note 5],No,DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],No,DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,DFS/TPC or [note 5],No,No,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
116,5580,5570–5590,,20,,,,U-NII-2C,DFS/TPC or [note 5],DFS/TPC or [note 5],DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],No,DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,DFS/TPC or [note 5],Yes,DFS,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
118,5590,5570–5610,,,40,,,U-NII-2C,DFS/TPC or [note 5],No,DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],No,DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,No,DFS,DFS,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
120,5600,5590–5610,,20,,,,U-NII-2C,DFS/TPC or [note 5],No,DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],No,DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,No,Yes,DFS,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
122,5610,5570–5650,,,,80,,U-NII-2C,DFS/TPC or [note 5],No,DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],No,DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,No,No,No,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
124,5620,5610–5630,,20,,,,U-NII-2C,DFS/TPC or [note 5],No,DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],No,DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,No,Yes,DFS,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
126,5630,5610–5650,,,40,,,U-NII-2C,DFS/TPC or [note 5],No,DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],No,DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,No,Yes,DFS,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
128,5640,5630–5650,,20,,,,U-NII-2C,DFS/TPC or [note 5],No,DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],No,DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,No,Yes,DFS,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
132,5660,5650–5670,,20,,,,U-NII-2C,DFS/TPC or [note 5],DFS/TPC or [note 5],DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],Indoors/TPC [note 10],DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,DFS/TPC or [note 5],Yes,DFS,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
134,5670,5650–5690,,,40,,,U-NII-2C,DFS/TPC or [note 5],DFS/TPC or [note 5],DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],Indoors/TPC [note 10],DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,DFS/TPC or [note 5],Yes,DFS,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
136,5680,5670–5690,,20,,,,U-NII-2C,DFS/TPC or [note 5],DFS/TPC or [note 5],DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],Indoors/TPC [note 10],DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,DFS/TPC,DFS/TPC or [note 5],Yes,DFS,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
138,5690,5650–5730,,,,80,,U-NII-2C and U-NII-3,DFS/TPC or [note 5],DFS/TPC or [note 5],DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8] + SRD (25 mW),Indoors/TPC [note 10],DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,"Indoors/DFS/TPC or[note 5][note 9]",DFS/TPC,No,DFS/TPC or [note 5],No,No,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
140,5700,5690–5710,,20,,,,U-NII-2C,DFS/TPC or [note 5],DFS/TPC or [note 5],DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8],Indoors/TPC [note 10],DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,Indoors[note 11],DFS/TPC,DFS/TPC,DFS/TPC or [note 5],Yes,DFS,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
142,5710,5690–5730,,,40,,,U-NII-2C and U-NII-3,DFS/TPC or [note 5],DFS/TPC or [note 5],DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8] + SRD (25 mW),Indoors/TPC [note 10],DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,Indoors[note 11],DFS/TPC,No,DFS/TPC or [note 5],No,No,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
144,5720,5710–5730,,20,,,,U-NII-2C and U-NII-3,DFS/TPC or [note 5],DFS/TPC or [note 5],DFS/TPC or [note 7] [note 8],DFS/TPC or [note 7] [note 8] + SRD (25 mW),Indoors/TPC [note 10],DFS/TPC,DFS/TPC or [note 6],DFS/TPC,No,Indoors[note 11],DFS/TPC,No,DFS/TPC or [note 5],No,No,DFS,DFS/TPC or [note 5],No,DFS/TPC or [note 5],No
149,5745,5735–5755,,20,,,,U-NII-3,Yes,Yes,SRD (200 mW),SRD (25 mW)[43],Indoors/TPC [note 10],No,Indoors,Yes,Yes,Indoors[note 11],Yes,No,Yes,No,Yes,Yes,Yes,"DFS/TPC/Fixed",Yes,Yes
151,5755,5735–5775,,,40,,,U-NII-3,Yes,Yes,SRD (200 mW),SRD (25 mW)[43],Indoors/TPC [note 10],No,Indoors,Yes,Yes,Indoors[note 11],Yes,No,Yes,No,Yes,Yes,Yes,"DFS/TPC/Fixed",Yes,Yes
153,5765,5755–5775,,20,,,,U-NII-3,Yes,Yes,SRD (200 mW),SRD (25 mW)[43],Indoors/TPC [note 10],No,Indoors,Yes,Yes,Indoors[note 11],Yes,No,Yes,No,Yes,Yes,Yes,"DFS/TPC/Fixed",Yes,Yes
155,5775,5735–5815,,,,80,,U-NII-3,Yes,Yes,SRD (200 mW),SRD (25 mW)[43],Indoors/TPC [note 10],No,Indoors,Yes,Indoors,Indoors[note 11],Yes,No,Yes,No,No,Yes,Yes,"DFS/TPC/Fixed",Yes,Yes
157,5785,5775–5795,,20,,,,U-NII-3,Yes,Yes,SRD (200 mW),SRD (25 mW)[43],Indoors/TPC [note 10],No,Indoors,Yes,Yes,Indoors[note 11],Yes,No,Yes,No,Yes,Yes,Yes,"DFS/TPC/Fixed",Yes,Yes
159,5795,5775–5815,,,40,,,U-NII-3,Yes,Yes,SRD (200 mW),SRD (25 mW)[43],Indoors/TPC [note 10],No,Indoors,Yes,Yes,Indoors[note 11],Yes,No,Yes,No,Yes,Yes,Yes,"DFS/TPC/Fixed",Yes,Yes
161,5805,5795–5815,,20,,,,U-NII-3,Yes,Yes,SRD (200 mW),SRD (25 mW)[43],Indoors/TPC [note 10],No,Indoors,Yes,Yes,Indoors[note 11],Yes,No,Yes,No,Yes,Yes,Yes,"DFS/TPC/Fixed",Yes,Yes
163,5815,5735–5895,,,,,160,U-NII-3 and U-NII-4,Indoors[44],No,No,No,Indoors/TPC [note 10],No,No,No,No,Indoors[note 11],No,No,No,No,No,No,No,No,No,No
165,5825,5815–5835,,20,,,,U-NII-3,Yes,Yes,SRD (200 mW),SRD (25 mW)[43],Indoors/TPC [note 10],No,Indoors,Yes,Yes,Indoors[note 11],Yes,No,Yes,No,Yes,Yes,Yes,"DFS/TPC/Fixed",Yes,No
167,5835,5815–5855,,,40,,,U-NII-3 and U-NII-4,Indoors[44],No,SRD (25 mW)[43],SRD (25 mW)[43],Indoors/TPC [note 10],No,Yes,No,No,Indoors[note 11],No,No,No,No,No,No,No,No,No,No
169,5845,5835–5855,,20,,,,U-NII-4,Indoors[44],No,SRD (25 mW)[43],SRD (25 mW)[43],Indoors/TPC [note 10],No,Yes,No,No,Indoors[note 11],No,No,No,No,No,No,No,No,No,No
171,5855,5815–5895,,,,80,,U-NII-3 and U-NII-4,Indoors[44],No,SRD (25 mW)[43],SRD (25 mW)[43],Indoors/TPC [note 10],No,Yes,No,No,Indoors[note 11],No,No,No,No,No,No,No,No,No,No
173,5865,5855–5875,,20,,,,U-NII-4,Indoors[44],No,SRD (25 mW)[43],SRD (25 mW)[43],Indoors/TPC [note 10],No,Yes,No,No,Indoors[note 11],No,No,No,No,No,No,No,No,No,No
175,5875,5855–5895,,,40,,,U-NII-4,Indoors[44],No,No,No,Indoors/TPC [note 10],No,No,No,No,No,No,No,No,No,No,No,No,No,No,No
177,5885,5875–5895,,20,,,,U-NII-4,Indoors[44],No,No,No,Indoors/TPC [note 10],No,No,No,No,No,No,No,No,No,No,No,No,No,No,No
182,5910,5905–5915,10,,,,,N/A,No,No,No,No,Indoors/TPC [note 10],Registration required,Un­known,No,No,No,No,No,No,No,No,No,No,No,No,No
183,5915,5905–5925,,20,,,,N/A,No,No,No,No,Indoors/TPC [note 10],Registration required,Un­known,No,No,No,No,No,No,No,No,No,No,No,No,No
184,5920,5915–5925,10,,,,,N/A,No,No,Indoors/SRD (250 mW),No,Indoors/TPC [note 10],Registration required,Un­known,No,No,No,No,No,No,No,No,No,No,No,No,No
187,5935,5930–5940,10,,,,,N/A,No,No,Indoors/SRD (250 mW),No,Indoors/TPC [note 10],Registration required,Un­known,No,No,No,No,No,No,No,No,No,No,No,No,No
188,5940,5930–5950,,20,,,,N/A,No,No,Indoors/SRD (250 mW),No,Indoors/TPC [note 10],Registration required,Un­known,No,No,No,No,No,No,No,No,No,No,No,No,No
189,5945,5940–5950,10,,,,,N/A,No,No,Indoors/SRD (250 mW),No,Indoors/TPC [note 10],Registration required,Un­known,No,No,No,No,No,No,No,No,No,No,No,No,No
192,5960,5950–5970,,20,,,,N/A,No,No,Indoors/SRD (250 mW),No,Indoors/TPC [note 10],Registration required,Un­known,No,No,No,No,No,No,No,No,No,No,No,No,No
196,5980,5970–5990,,20,,,,N/A,No,No,Indoors/SRD (250 mW),No,Indoors/TPC [note 10],Registration required,Un­known,No,No,No,No,No,No,No,No,No,No,No,No,No
*/

import { map } from 'lodash';

const channelMap = {
  ch1: {
    channel: 1,
    centerFreq: 2412,
    displayChannel: 1,
    freqRange: {
      from: 2401,
      to: 2423
    },
    width: 20
  },
  ch10: {
    channel: 10,
    centerFreq: 2457,
    displayChannel: 10,
    freqRange: {
      from: 2446,
      to: 2468
    },
    width: 20
  },
  ch100: {
    channel: 100,
    centerFreq: 5500,
    displayChannel: 100,
    freqRange: {
      from: 5490,
      to: 5510
    },
    width: 20
  },
  ch102: {
    channel: 102,
    centerFreq: 5510,
    displayChannel: 100,
    freqRange: {
      from: 5490,
      to: 5530
    },
    width: 40
  },
  ch104: {
    channel: 104,
    centerFreq: 5520,
    displayChannel: 104,
    freqRange: {
      from: 5510,
      to: 5530
    },
    width: 20
  },
  ch106: {
    channel: 106,
    centerFreq: 5530,
    displayChannel: 100,
    freqRange: {
      from: 5490,
      to: 5570
    },
    width: 80
  },
  ch108: {
    channel: 108,
    centerFreq: 5540,
    displayChannel: 108,
    freqRange: {
      from: 5530,
      to: 5550
    },
    width: 20
  },
  ch11: {
    channel: 11,
    centerFreq: 2462,
    displayChannel: 11,
    freqRange: {
      from: 2451,
      to: 2473
    },
    width: 20
  },
  ch110: {
    channel: 110,
    centerFreq: 5550,
    displayChannel: 108,
    freqRange: {
      from: 5530,
      to: 5570
    },
    width: 40
  },
  ch112: {
    channel: 112,
    centerFreq: 5560,
    displayChannel: 112,
    freqRange: {
      from: 5550,
      to: 5570
    },
    width: 20
  },
  ch114: {
    channel: 114,
    centerFreq: 5570,
    displayChannel: 100,
    freqRange: {
      from: 5490,
      to: 5650
    },
    width: 160
  },
  ch116: {
    channel: 116,
    centerFreq: 5580,
    displayChannel: 116,
    freqRange: {
      from: 5570,
      to: 5590
    },
    width: 20
  },
  ch118: {
    channel: 118,
    centerFreq: 5590,
    displayChannel: 116,
    freqRange: {
      from: 5570,
      to: 5610
    },
    width: 40
  },
  ch12: {
    channel: 12,
    centerFreq: 2467,
    displayChannel: 12,
    freqRange: {
      from: 2456,
      to: 2478
    },
    width: 20
  },
  ch120: {
    channel: 120,
    centerFreq: 5600,
    displayChannel: 120,
    freqRange: {
      from: 5590,
      to: 5610
    },
    width: 20
  },
  ch122: {
    channel: 122,
    centerFreq: 5610,
    displayChannel: 116,
    freqRange: {
      from: 5570,
      to: 5650
    },
    width: 80
  },
  ch124: {
    channel: 124,
    centerFreq: 5620,
    displayChannel: 124,
    freqRange: {
      from: 5610,
      to: 5630
    },
    width: 20
  },
  ch126: {
    channel: 126,
    centerFreq: 5630,
    displayChannel: 124,
    freqRange: {
      from: 5610,
      to: 5650
    },
    width: 40
  },
  ch128: {
    channel: 128,
    centerFreq: 5640,
    displayChannel: 128,
    freqRange: {
      from: 5630,
      to: 5650
    },
    width: 20
  },
  ch13: {
    channel: 13,
    centerFreq: 2472,
    displayChannel: 13,
    freqRange: {
      from: 2461,
      to: 2483
    },
    width: 20
  },
  ch132: {
    channel: 132,
    centerFreq: 5660,
    displayChannel: 132,
    freqRange: {
      from: 5650,
      to: 5670
    },
    width: 20
  },
  ch134: {
    channel: 134,
    centerFreq: 5670,
    displayChannel: 132,
    freqRange: {
      from: 5650,
      to: 5690
    },
    width: 40
  },
  ch136: {
    channel: 136,
    centerFreq: 5680,
    displayChannel: 136,
    freqRange: {
      from: 5670,
      to: 5690
    },
    width: 20
  },
  ch138: {
    channel: 138,
    centerFreq: 5690,
    displayChannel: 132,
    freqRange: {
      from: 5650,
      to: 5730
    },
    width: 80
  },
  ch14: {
    channel: 14,
    centerFreq: 2484,
    displayChannel: 14,
    freqRange: {
      from: 2473,
      to: 2495
    },
    width: 20
  },
  ch140: {
    channel: 140,
    centerFreq: 5700,
    displayChannel: 140,
    freqRange: {
      from: 5690,
      to: 5710
    },
    width: 20
  },
  ch142: {
    channel: 142,
    centerFreq: 5710,
    displayChannel: 140,
    freqRange: {
      from: 5690,
      to: 5730
    },
    width: 40
  },
  ch144: {
    channel: 144,
    centerFreq: 5720,
    displayChannel: 144,
    freqRange: {
      from: 5710,
      to: 5730
    },
    width: 20
  },
  ch149: {
    channel: 149,
    centerFreq: 5745,
    displayChannel: 149,
    freqRange: {
      from: 5735,
      to: 5755
    },
    width: 20
  },
  ch151: {
    channel: 151,
    centerFreq: 5755,
    displayChannel: 149,
    freqRange: {
      from: 5735,
      to: 5775
    },
    width: 40
  },
  ch153: {
    channel: 153,
    centerFreq: 5765,
    displayChannel: 153,
    freqRange: {
      from: 5755,
      to: 5775
    },
    width: 20
  },
  ch155: {
    channel: 155,
    centerFreq: 5775,
    displayChannel: 149,
    freqRange: {
      from: 5735,
      to: 5815
    },
    width: 80
  },
  ch157: {
    channel: 157,
    centerFreq: 5785,
    displayChannel: 157,
    freqRange: {
      from: 5775,
      to: 5795
    },
    width: 20
  },
  ch159: {
    channel: 159,
    centerFreq: 5795,
    displayChannel: 157,
    freqRange: {
      from: 5775,
      to: 5815
    },
    width: 40
  },
  ch161: {
    channel: 161,
    centerFreq: 5805,
    displayChannel: 161,
    freqRange: {
      from: 5795,
      to: 5815
    },
    width: 20
  },
  ch163: {
    channel: 163,
    centerFreq: 5815,
    displayChannel: 149,
    freqRange: {
      from: 5735,
      to: 5895
    },
    width: 160
  },
  ch165: {
    channel: 165,
    centerFreq: 5825,
    displayChannel: 165,
    freqRange: {
      from: 5815,
      to: 5835
    },
    width: 20
  },
  ch167: {
    channel: 167,
    centerFreq: 5835,
    displayChannel: 165,
    freqRange: {
      from: 5815,
      to: 5855
    },
    width: 40
  },
  ch169: {
    channel: 169,
    centerFreq: 5845,
    displayChannel: 169,
    freqRange: {
      from: 5835,
      to: 5855
    },
    width: 20
  },
  ch171: {
    channel: 171,
    centerFreq: 5855,
    displayChannel: 165,
    freqRange: {
      from: 5815,
      to: 5895
    },
    width: 80
  },
  ch173: {
    channel: 173,
    centerFreq: 5865,
    displayChannel: 173,
    freqRange: {
      from: 5855,
      to: 5875
    },
    width: 20
  },
  ch175: {
    channel: 175,
    centerFreq: 5875,
    displayChannel: 173,
    freqRange: {
      from: 5855,
      to: 5895
    },
    width: 40
  },
  ch177: {
    channel: 177,
    centerFreq: 5885,
    displayChannel: 177,
    freqRange: {
      from: 5875,
      to: 5895
    },
    width: 20
  },
  ch182: {
    channel: 182,
    centerFreq: 5910,
    displayChannel: 182,
    freqRange: {
      from: 5905,
      to: 5915
    },
    width: 10
  },
  ch183: {
    channel: 183,
    centerFreq: 5915,
    displayChannel: 183,
    freqRange: {
      from: 5905,
      to: 5925
    },
    width: 20
  },
  ch184: {
    channel: 184,
    centerFreq: 5920,
    displayChannel: 184,
    freqRange: {
      from: 5915,
      to: 5925
    },
    width: 10
  },
  ch187: {
    channel: 187,
    centerFreq: 5935,
    displayChannel: 187,
    freqRange: {
      from: 5930,
      to: 5940
    },
    width: 10
  },
  ch188: {
    channel: 188,
    centerFreq: 5940,
    displayChannel: 188,
    freqRange: {
      from: 5930,
      to: 5950
    },
    width: 20
  },
  ch189: {
    channel: 189,
    centerFreq: 5945,
    displayChannel: 189,
    freqRange: {
      from: 5940,
      to: 5950
    },
    width: 10
  },
  ch192: {
    channel: 192,
    centerFreq: 5960,
    displayChannel: 192,
    freqRange: {
      from: 5950,
      to: 5970
    },
    width: 20
  },
  ch196: {
    channel: 196,
    centerFreq: 5980,
    displayChannel: 196,
    freqRange: {
      from: 5970,
      to: 5990
    },
    width: 20
  },
  ch2: {
    channel: 2,
    centerFreq: 2417,
    displayChannel: 2,
    freqRange: {
      from: 2406,
      to: 2428
    },
    width: 20
  },
  ch3: {
    channel: 3,
    centerFreq: 2422,
    displayChannel: 3,
    freqRange: {
      from: 2411,
      to: 2433
    },
    width: 20
  },
  ch36: {
    channel: 36,
    centerFreq: 5180,
    displayChannel: 36,
    freqRange: {
      from: 5170,
      to: 5190
    },
    width: 20
  },
  ch38: {
    channel: 38,
    centerFreq: 5190,
    displayChannel: 36,
    freqRange: {
      from: 5170,
      to: 5210
    },
    width: 40
  },
  ch4: {
    channel: 4,
    centerFreq: 2427,
    displayChannel: 4,
    freqRange: {
      from: 2416,
      to: 2438
    },
    width: 20
  },
  ch40: {
    channel: 40,
    centerFreq: 5200,
    displayChannel: 40,
    freqRange: {
      from: 5190,
      to: 5210
    },
    width: 20
  },
  ch42: {
    channel: 42,
    centerFreq: 5210,
    displayChannel: 36,
    freqRange: {
      from: 5170,
      to: 5250
    },
    width: 80
  },
  ch44: {
    channel: 44,
    centerFreq: 5220,
    displayChannel: 44,
    freqRange: {
      from: 5210,
      to: 5230
    },
    width: 20
  },
  ch46: {
    channel: 46,
    centerFreq: 5230,
    displayChannel: 44,
    freqRange: {
      from: 5210,
      to: 5250
    },
    width: 40
  },
  ch48: {
    channel: 48,
    centerFreq: 5240,
    displayChannel: 48,
    freqRange: {
      from: 5230,
      to: 5250
    },
    width: 20
  },
  ch5: {
    channel: 5,
    centerFreq: 2432,
    displayChannel: 5,
    freqRange: {
      from: 2421,
      to: 2443
    },
    width: 20
  },
  ch50: {
    channel: 50,
    centerFreq: 5250,
    displayChannel: 36,
    freqRange: {
      from: 5170,
      to: 5330
    },
    width: 160
  },
  ch52: {
    channel: 52,
    centerFreq: 5260,
    displayChannel: 52,
    freqRange: {
      from: 5250,
      to: 5270
    },
    width: 20
  },
  ch54: {
    channel: 54,
    centerFreq: 5270,
    displayChannel: 52,
    freqRange: {
      from: 5250,
      to: 5290
    },
    width: 40
  },
  ch56: {
    channel: 56,
    centerFreq: 5280,
    displayChannel: 56,
    freqRange: {
      from: 5270,
      to: 5290
    },
    width: 20
  },
  ch58: {
    channel: 58,
    centerFreq: 5290,
    displayChannel: 52,
    freqRange: {
      from: 5250,
      to: 5330
    },
    width: 80
  },
  ch6: {
    channel: 6,
    centerFreq: 2437,
    displayChannel: 6,
    freqRange: {
      from: 2426,
      to: 2448
    },
    width: 20
  },
  ch60: {
    channel: 60,
    centerFreq: 5300,
    displayChannel: 60,
    freqRange: {
      from: 5290,
      to: 5310
    },
    width: 20
  },
  ch62: {
    channel: 62,
    centerFreq: 5310,
    displayChannel: 60,
    freqRange: {
      from: 5290,
      to: 5330
    },
    width: 40
  },
  ch64: {
    channel: 64,
    centerFreq: 5320,
    displayChannel: 64,
    freqRange: {
      from: 5310,
      to: 5330
    },
    width: 20
  },
  ch68: {
    channel: 68,
    centerFreq: 5340,
    displayChannel: 68,
    freqRange: {
      from: 5330,
      to: 5350
    },
    width: 20
  },
  ch7: {
    channel: 7,
    centerFreq: 2442,
    displayChannel: 7,
    freqRange: {
      from: 2431,
      to: 2453
    },
    width: 20
  },
  ch8: {
    channel: 8,
    centerFreq: 2447,
    displayChannel: 8,
    freqRange: {
      from: 2436,
      to: 2458
    },
    width: 20
  },
  ch9: {
    channel: 9,
    centerFreq: 2452,
    displayChannel: 9,
    freqRange: {
      from: 2441,
      to: 2463
    },
    width: 20
  },
  ch96: {
    channel: 96,
    centerFreq: 5480,
    displayChannel: 96,
    freqRange: {
      from: 5470,
      to: 5490
    },
    width: 20
  }
};
const freqMap = {
  freq2412: 1,
  freq2417: 2,
  freq2422: 3,
  freq2427: 4,
  freq2432: 5,
  freq2437: 6,
  freq2442: 7,
  freq2447: 8,
  freq2452: 9,
  freq2457: 10,
  freq2462: 11,
  freq2467: 12,
  freq2472: 13,
  freq2484: 14,
  freq5180: 36,
  freq5190: 38,
  freq5200: 40,
  freq5210: 42,
  freq5220: 44,
  freq5230: 46,
  freq5240: 48,
  freq5250: 50,
  freq5260: 52,
  freq5270: 54,
  freq5280: 56,
  freq5290: 58,
  freq5300: 60,
  freq5310: 62,
  freq5320: 64,
  freq5340: 68,
  freq5480: 96,
  freq5500: 100,
  freq5510: 102,
  freq5520: 104,
  freq5530: 106,
  freq5540: 108,
  freq5550: 110,
  freq5560: 112,
  freq5570: 114,
  freq5580: 116,
  freq5590: 118,
  freq5600: 120,
  freq5610: 122,
  freq5620: 124,
  freq5630: 126,
  freq5640: 128,
  freq5660: 132,
  freq5670: 134,
  freq5680: 136,
  freq5690: 138,
  freq5700: 140,
  freq5710: 142,
  freq5720: 144,
  freq5745: 149,
  freq5755: 151,
  freq5765: 153,
  freq5775: 155,
  freq5785: 157,
  freq5795: 159,
  freq5805: 161,
  freq5815: 163,
  freq5825: 165,
  freq5835: 167,
  freq5845: 169,
  freq5855: 171,
  freq5865: 173,
  freq5875: 175,
  freq5885: 177,
  freq5910: 182,
  freq5915: 183,
  freq5920: 184,
  freq5935: 187,
  freq5940: 188,
  freq5945: 189,
  freq5960: 192,
  freq5980: 196
};

export function FreqToChannel(freq) {
  const channel = freqMap[`freq${freq}`];
  return channel === undefined ? 0 : channel;
}

export function ChannelToFreq(channel) {
  const chObj = channelMap[`ch${channel}`];
  return chObj === undefined ? chObj.centerFreq : 0;
}

export function ChannelFormat(channel, width, centerFreq1, centerFreq2) {
  const chObj = channelMap[`ch${channel}`];
  // if (centerFreq1) {
  //   const chNum = freqMap[`freq${centerFreq1}`];
  //   if (chNum) {
  //     chObj = channelMap[`ch${chNum}`];
  //   }
  // } else {
  //   chObj = channelMap[`ch${channel}`];
  // }
  // console.log('width', width);
  if (!width) width = 20;

  if (chObj) {
    // if (chObj.channel <= 14 && width === 40) {
    //   return `${chObj.channel} (${chObj.centerFreq - 20}-${chObj.centerFreq + 20} MHz)`;
    // }
    // return `${chObj.channel} (${chObj.freqRange.from}-${chObj.freqRange.to} MHz)`;
    // if (chObj.channel <= 14 && width === 40) {
    return `${channel} (${chObj.centerFreq} MHz) / ${width}MHz`;
    // }
    // return `${channel} (${chObj.freqRange.from}-${chObj.freqRange.to} MHz) / ${width}MHz`;
  }

  // if (centerFreq1) return `${channel} (${centerFreq1} MHz) / ${width}MHz`;
  return `${channel}`;
}

export function ChannelList(band, channelWidth) {
  const cmp = (a, b) => {
    if (a.channel < b.channel) return -1;
    if (a.channel > b.channel) return 1;
    return 0;
  };
  console.log(band, channelWidth);
  return Object.entries(channelMap)
    .map(([key, value]) => value)
    .filter((c) => {
      if (band === 'band_2g') {
        return c.channel <= 11;
      }
      if (band === 'band_5g') {
        if (channelWidth !== c.width) {
          return false;
        }
        return c.channel >= 36 && c.channel <= 165;
      }
      return false;
    })
    .sort(cmp);
}
