import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';

import { removeMembers } from '../../../actions/member';
import { dialogOpen } from '../../../actions/dialog';

// ----------------------------------------------------------------------

export default function MemberMoreMenu({ location, member, myPermission, networkItems }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            dispatch(
              dialogOpen(
                'confirmItems',
                'Remove Member',
                {
                  message: 'Are you sure you want to remove the following member?',
                  items: [
                    `${member.name.length > 0 ? `${member.name} (${member.email})` : member.email}`
                  ]
                },
                (data) => dispatch(removeMembers(location.org_id, myPermission, [member]))
              )
            );
          }}
        >
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Remove" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {/* <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            dispatch(
              dialogOpen(
                'editDeviceNetwork',
                'Edit Device Network',
                {
                  action: 'move',
                  networkItems,
                  selectedNetworkID: networkItems[0].network_id
                },
                (data) => {
                  const { action, selectedNetworkID } = data;

                  if (action === 'move') {
                    return dispatch(
                      moveDevicesToNetwork(
                        location.org_id,
                        selectedNetworkID,
                        [device].filter((d) => d.network_id !== selectedNetworkID)
                      )
                    );
                  }

                  return dispatch(
                    removeDevicesFromNetwork(
                      location.org_id,
                      [device].filter((d) => d.network_id !== null)
                    )
                  );
                }
              )
            );
          }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit Network" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}
      </Menu>
    </>
  );
}
