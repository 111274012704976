/* eslint-disable camelcase */
import { load, set, triggerReload } from '../reducers/devices';
import { load as load_scan } from '../reducers/scan';
import api from './api';

export const loadDevices = (location, filter) => (dispatch) => {
  const { group_id, network_id } = location;
  let apiName = 'getOrgDevices';

  if (network_id && network_id.length > 0) {
    apiName = 'getNetworkDevices';
  } else if (group_id && group_id.length > 0) {
    apiName = 'getGroupDevices';
  }

  return api.call(apiName, {
    dispatch,
    urlParams: location,
    queryParams: filter,
    onResp: (devices) => {
      dispatch(load(devices));
      return devices;
    }
  });
};

export const registerDevices = (org_id, serial_numbers) => (dispatch) =>
  Promise.allSettled(
    serial_numbers.map((sn) =>
      api.call('registerDevice', {
        dispatch,
        urlParams: { org_id },
        data: {
          serial_number: sn
        },
        onError: (error) => ({ message: error.message, serial_number: sn })
      })
    )
  ).then((results) => {
    let success = 0;
    let message = '';
    results.forEach((r) => {
      if (r.status === 'fulfilled') success += 1;
      else {
        if (message.length > 0) message += '\n';
        message += `${r.reason.serial_number}: ${r.reason.message}`;
      }
    });

    if (success > 0) {
      dispatch(triggerReload());
    }

    if (message.length > 0) {
      throw new Error(message);
    }
  });

export const deregisterDevices = (org_id, devices) => (dispatch) =>
  Promise.allSettled(
    devices.map((device) => {
      if (device.network_id !== null) {
        return api
          .call('removeDeviceFromNetwork', {
            dispatch,
            urlParams: { org_id, network_id: device.network_id, device_id: device.id },
            onError: (error) => ({ message: error.message, device })
          })
          .then(() =>
            api.call('deregisterDevice', {
              dispatch,
              urlParams: { org_id, device_id: device.id },
              onError: (error) => ({ message: error.message, device })
            })
          );
      }
      return api.call('deregisterDevice', {
        dispatch,
        urlParams: { org_id, device_id: device.id },
        onError: (error) => ({ message: error.message, device })
      });
    })
  ).then((results) => {
    let success = 0;
    let message = '';
    results.forEach((r) => {
      if (r.status === 'fulfilled') success += 1;
      else {
        if (message.length > 0) message += '\n';
        message += `${r.reason.device.name} (${r.reason.device.display_mac}): ${r.reason.message}`;
      }
    });

    if (success > 0) {
      dispatch(triggerReload());
    }

    if (message.length > 0) {
      throw new Error(message);
    }
  });

export const moveDevicesToNetwork = (org_id, network_id, devices) => (dispatch) =>
  Promise.allSettled(
    devices.map((device) => {
      if (device.network_id !== null) {
        return api
          .call('removeDeviceFromNetwork', {
            dispatch,
            urlParams: { org_id, network_id: device.network_id, device_id: device.id },
            onError: (error) => ({ message: error.message, device })
          })
          .then(() =>
            api.call('addDeviceToNetwork', {
              dispatch,
              urlParams: { org_id, network_id },
              data: {
                device_id: device.id
              },
              onError: (error) => ({ message: error.message, device })
            })
          );
      }
      return api.call('addDeviceToNetwork', {
        dispatch,
        urlParams: { org_id, network_id },
        data: {
          device_id: device.id
        },
        onError: (error) => ({ message: error.message, device })
      });
    })
  ).then((results) => {
    let success = 0;
    let message = '';
    results.forEach((r) => {
      if (r.status === 'fulfilled') success += 1;
      else {
        if (message.length > 0) message += '\n';
        message += `${r.reason.device.name} (${r.reason.device.display_mac}): ${r.reason.message}`;
      }
    });

    if (success > 0) {
      dispatch(triggerReload());
    }

    if (message.length > 0) {
      throw new Error(message);
    }
  });

export const removeDevicesFromNetwork = (org_id, devices) => (dispatch) =>
  Promise.allSettled(
    devices.map((device) =>
      api.call('removeDeviceFromNetwork', {
        dispatch,
        urlParams: { org_id, network_id: device.network_id, device_id: device.id },
        onError: (error) => ({ message: error.message, device })
      })
    )
  ).then((results) => {
    let success = 0;
    let message = '';
    results.forEach((r) => {
      if (r.status === 'fulfilled') success += 1;
      else {
        if (message.length > 0) message += '\n';
        message += `${r.reason.device.name} (${r.reason.device.display_mac}): ${r.reason.message}`;
      }
    });

    if (success > 0) {
      dispatch(triggerReload());
    }

    if (message.length > 0) {
      throw new Error(message);
    }
  });

export const modifyDeviceName = (org_id, network_id, device_id, name) => (dispatch) => {
  if (network_id) {
    return api.call('modifyNetworkDevice', {
      dispatch,
      urlParams: { org_id, network_id, device_id },
      data: {
        name
      },
      onResp: () => {
        dispatch(set({ id: device_id, org_id, network_id, name }));
      }
    });
  }

  return api.call('modifyOrgDevice', {
    dispatch,
    urlParams: { org_id, device_id },
    data: {
      name
    },
    onResp: () => {
      dispatch(set({ id: device_id, org_id, network_id, name }));
    }
  });
};

export const upgradeDevicesFirmware = (org_id, devices, modelFirmwares) => (dispatch) =>
  Promise.allSettled(
    devices.map((device) =>
      api.call('upgradeDeviceFirmware', {
        dispatch,
        urlParams: { org_id, network_id: device.network_id, device_id: device.id },
        data: {
          version: modelFirmwares[device.model]
        },
        onError: (error) => ({ message: error.message, device })
      })
    )
  ).then((results) => {
    let success = 0;
    let message = '';
    results.forEach((r) => {
      if (r.status === 'fulfilled') success += 1;
      else {
        if (message.length > 0) message += '\n';
        message += `${r.reason.device.name} (${r.reason.device.display_mac}): ${r.reason.message}`;
      }
    });

    if (message.length > 0) {
      throw new Error(message);
    }
  });

export const rebootDevices = (org_id, devices) => (dispatch) =>
  Promise.allSettled(
    devices.map((device) =>
      api.call('rebootDevice', {
        dispatch,
        urlParams: { org_id, network_id: device.network_id, device_id: device.id },
        onError: (error) => ({ message: error.message, device })
      })
    )
  ).then((results) => {
    let message = '';
    results.forEach((r) => {
      if (r.status !== 'fulfilled') {
        if (message.length > 0) message += '\n';
        message += `${r.reason.device.name} (${r.reason.device.display_mac}): ${r.reason.message}`;
      }
    });

    if (message.length > 0) {
      throw new Error(message);
    }
  });

export const subscribeDevicesStatus = (network_ids, filter) => (dispatch) => {
  const topics = [
    // {
    //   topic: 'stat',
    //   persistent: true,
    //   options: {
    //     interval: 5
    //   }
    // },
    {
      topic: 'device_info',
      persistent: true,
      options: {
        interval: 10
      }
    },
    {
      topic: 'device_event',
      persistent: true
    }
  ];
  const source = api.sse(topics, network_ids, filter);
  source.addEventListener(
    'publish',
    (e) => {
      const data = JSON.parse(e.data);
      console.log(data);
      switch (data.tid) {
        case 0: {
          dispatch(set({ mac: data.device, ...data.content }));
          break;
        }
        case 1: {
          const { event, time } = data.content;
          if (event === 'online') {
            const { wan_ip, firmware_version } = data.content.data;
            dispatch(
              set({
                mac: data.device,
                connection: { online: true, wan_ip, firmware_version, update_at: time }
              })
            );
          } else if (event === 'offline') {
            dispatch(set({ mac: data.device, connection: { online: false, update_at: time } }));
          }
          break;
        }
        default:
      }
    },
    false
  );

  return source;
};

export const scan = (org_id, network_id, device_id, band) => (dispatch) =>
  api.call('scanAPs', {
    dispatch,
    urlParams: { org_id, network_id, device_id },
    data: {
      ...(band !== undefined && {
        band
      })
    },
    onResp: (aps) => {
      const ret = dispatch(load_scan(aps));
      // console.log('ret load_scan aps =>', ret);
      return ret.payload;
    }
  });

export const configChannel =
  (org_id, network_id, device_id, band, channel, channel_width, country_code) => (dispatch) =>
    api.call('configChannel', {
      dispatch,
      urlParams: { org_id, network_id, device_id },
      data: {
        radios: [{ band, channel, channel_width, country: country_code }]
      }
    });
