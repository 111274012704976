import { show, hide, updateData } from '../reducers/dialog';

export const dialogOpen = (type, title, data, onApply) => (dispatch) => {
  dispatch(show({ type, title, data, onApply }));
};

export const dialogClose = () => (dispatch) => {
  dispatch(hide());
};

export const dialogSetData = (data) => (dispatch) => {
  dispatch(updateData(data));
};
