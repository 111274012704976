import { createSlice } from '@reduxjs/toolkit';

const initialState = { open: false, severity: 'success', message: '' };

const slice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    show(state, action) {
      return {
        open: true,
        severity: action.payload.severity,
        message: action.payload.message
      };
    },
    hide(state) {
      return {
        ...state,
        open: false
      };
    }
  }
});

export const { show, hide } = slice.actions;
export default slice.reducer;
