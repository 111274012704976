import { createSlice } from '@reduxjs/toolkit';

const initialState = { open: false };

const slice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    show(state, action) {
      return {
        ...action.payload,
        open: true
      };
    },
    hide(state) {
      return {
        ...state,
        open: false
      };
    },
    updateData(state, action) {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      };
    }
  }
});

export const { show, hide, updateData } = slice.actions;
export default slice.reducer;
