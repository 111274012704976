import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';
import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import SignalCellular0BarIcon from '@material-ui/icons/SignalCellular0Bar';
import SignalCellular1BarIcon from '@material-ui/icons/SignalCellular1Bar';
import SignalCellular2BarIcon from '@material-ui/icons/SignalCellular2Bar';
import SignalCellular3BarIcon from '@material-ui/icons/SignalCellular3Bar';
import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';

// ----------------------------------------------------------------------

SignalPercent.propTypes = {
  signal: PropTypes.number,
  sx: PropTypes.object
};

export default function SignalPercent({ signal, sx }) {
  const percent = useMemo(() => Math.floor(Math.min(((signal + 110) / 70) * 100, 100)), [signal]);

  if (percent <= 0)
    return (
      <Box display="flex" alignItems="center">
        <SignalCellular0BarIcon sx={{ fontSize: 16, marginRight: 0.5, color: 'silver', ...sx }} />
        0%
      </Box>
    );
  if (percent < 25)
    return (
      <Box display="flex" alignItems="center">
        <SignalCellular1BarIcon sx={{ fontSize: 16, marginRight: 0.5, color: 'red', ...sx }} />
        {`${percent}%`}
      </Box>
    );
  if (percent < 50)
    return (
      <Box display="flex" alignItems="center">
        <SignalCellular2BarIcon sx={{ fontSize: 16, marginRight: 0.5, color: 'yellow', ...sx }} />
        {`${percent}%`}
      </Box>
    );
  if (percent < 75)
    return (
      <Box display="flex" alignItems="center">
        <SignalCellular3BarIcon sx={{ fontSize: 16, marginRight: 0.5, color: 'lime', ...sx }} />
        {`${percent}%`}
      </Box>
    );
  return (
    <Box display="flex" alignItems="center">
      <SignalCellular4BarIcon sx={{ fontSize: 16, marginRight: 0.5, color: 'green', ...sx }} />
      {`${percent}%`}
    </Box>
  );
}
