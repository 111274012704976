/* eslint-disable camelcase */
import axios from 'axios';
import { logout } from '../reducers/user';

export const SERVER_ADDRESS = 'sim.dev.engenius.ai';
export const XAPI_URL = `https://${SERVER_ADDRESS}/web/xapi/v1/`;
export const API_URL = `https://${SERVER_ADDRESS}/web/api/v1/`;
export const API_TABLE = {
  signin: {
    method: 'post',
    path: 'signin',
    no_auth: true
  },
  signup: {
    method: 'post',
    path: 'signup',
    no_auth: true
  },
  getOrgs: {
    method: 'get',
    path: 'orgs'
  },
  addOrg: {
    method: 'post',
    path: 'orgs'
  },
  delOrg: {
    method: 'delete',
    path: 'orgs/:org_id'
  },
  modifyOrg: {
    method: 'patch',
    path: 'orgs/:org_id'
  },
  addGroup: {
    method: 'post',
    path: 'orgs/:org_id/groups'
  },
  delGroup: {
    method: 'delete',
    path: 'orgs/:org_id/groups/:group_id'
  },
  modifyGroup: {
    method: 'patch',
    path: 'orgs/:org_id/groups/:group_id'
  },
  addNetwork: {
    method: 'post',
    path: 'orgs/:org_id/networks'
  },
  delNetwork: {
    method: 'delete',
    path: 'orgs/:org_id/networks/:network_id'
  },
  modifyNetwork: {
    method: 'patch',
    path: 'orgs/:org_id/networks/:network_id'
  },
  registerDevice: {
    method: 'post',
    path: 'orgs/:org_id/devices'
  },
  deregisterDevice: {
    method: 'delete',
    path: 'orgs/:org_id/devices/:device_id'
  },
  addDeviceToNetwork: {
    method: 'post',
    path: 'orgs/:org_id/networks/:network_id/devices'
  },
  removeDeviceFromNetwork: {
    method: 'delete',
    path: 'orgs/:org_id/networks/:network_id/devices/:device_id'
  },
  getOrgDevices: {
    method: 'get',
    path: 'orgs/:org_id/devices'
  },
  getGroupDevices: {
    method: 'get',
    path: 'orgs/:org_id/groups/:group_id/devices'
  },
  getNetworkDevices: {
    method: 'get',
    path: 'orgs/:org_id/networks/:network_id/devices'
  },
  modifyOrgDevice: {
    method: 'patch',
    path: 'orgs/:org_id/devices/:device_id'
  },
  modifyNetworkDevice: {
    method: 'patch',
    path: 'orgs/:org_id/networks/:network_id/devices/:device_id'
  },
  upgradeDeviceFirmware: {
    method: 'post',
    path: 'orgs/:org_id/networks/:network_id/devices/:device_id/firmware_upgrade'
  },
  rebootDevice: {
    method: 'post',
    path: 'orgs/:org_id/networks/:network_id/devices/:device_id/reboot'
  },
  getModelsFirmwares: {
    method: 'get',
    path: 'firmwares'
  },
  confirm: {
    method: 'post',
    path: 'confirm'
  },
  getOrgMembers: {
    method: 'get',
    path: 'orgs/:org_id/members'
  },
  getNetworkMembers: {
    method: 'get',
    path: 'orgs/:org_id/networks/:network_id/members'
  },
  getGroupMembers: {
    method: 'get',
    path: 'orgs/:org_id/groups/:group_id/members'
  },
  inviteOrgMember: {
    method: 'post',
    path: 'orgs/:org_id/invite'
  },
  inviteNetworkMember: {
    method: 'post',
    path: 'orgs/:org_id/networks/:network_id/invite'
  },
  modifyMemberPermission: {
    method: 'patch',
    path: 'orgs/:org_id/members'
  },
  scanAPs: {
    method: 'post',
    path: 'orgs/:org_id/networks/:network_id/devices/:device_id/scan'
  },
  configChannel: {
    method: 'post',
    path: 'orgs/:org_id/networks/:network_id/devices/:device_id/config'
  }
};

const call = (name, { urlParams, queryParams, data, onResp, onError, dispatch }) => {
  const api = API_TABLE[name];
  if (!api) {
    throw new Error(`API '${name}' is not defined`);
  }

  const instance = axios.create();
  instance.interceptors.request.use((config) => {
    if (!config.url) {
      return config;
    }

    const currentUrl = new URL(config.url, config.baseURL);
    // parse pathName to implement variables
    Object.entries(config.urlParams || {}).forEach(([k, v]) => {
      currentUrl.pathname = currentUrl.pathname.replace(`:${k}`, encodeURIComponent(v));
    });

    const authPart =
      currentUrl.username && currentUrl.password
        ? `${currentUrl.username}:${currentUrl.password}`
        : '';
    return {
      ...config,
      baseURL: `${currentUrl.protocol}//${authPart}${currentUrl.host}`,
      url: currentUrl.pathname
    };
  });

  const config = {
    baseURL: API_URL,
    url: api.path,
    method: api.method,
    urlParams: urlParams || {},
    params: queryParams || {}
  };

  if (!api.no_auth) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      const { expires_at, access_token } = user;
      const timestamp = Math.floor(Date.now() / 1000);
      if (timestamp >= expires_at) {
        return dispatch(logout());
      }
      config.headers = { Authorization: `Bearer ${access_token}` };
    }
  }

  if (data) {
    config.data = data;
  }

  return instance
    .request(config)
    .then((response) => {
      if (onResp) {
        return Promise.resolve(onResp(response.data));
      }
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      const error = {};
      if (err.response) {
        error.status = err.response.status;
        error.message = err.response.data.message;
        if (error.status === 401) {
          dispatch(logout());
        }
      } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        error.message = err.request.responseText || 'Network request failed';
      } else {
        // Something happened in setting up the request that triggered an Error
        error.message = err.message || err.toString();
      }

      if (onError) {
        return Promise.reject(onError(error));
      }
      return Promise.reject(error);
    });
};

const deviceWebLink = (org_id, network_id, device_id) => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user) {
    return `${API_URL}orgs/${org_id}/networks/${network_id}/devices/${device_id}/web_session?access_token=${encodeURIComponent(
      user.access_token
    )}`;
  }
  return null;
};

const sse = (topics, network_ids, filter) => {
  const user = JSON.parse(localStorage.getItem('user'));
  let url = `${XAPI_URL}networks/${encodeURIComponent(
    network_ids.join(',')
  )}/topics/${encodeURIComponent(
    Buffer.from(JSON.stringify(topics)).toString('base64')
  )}?access_token=${encodeURIComponent(user.access_token)}`;

  if (filter) {
    let queryParams = '';
    const { macs, models, deviceTypes } = filter;
    if (macs) {
      queryParams += `&mac=${encodeURIComponent(macs.join(','))}`;
    }
    if (models) {
      queryParams += `&model=${encodeURIComponent(models.join(','))}`;
    }
    if (deviceTypes) {
      queryParams += `&deviceType=${encodeURIComponent(deviceTypes.join(','))}`;
    }
    url += queryParams;
  }

  return new EventSource(url);
};

export default {
  call,
  deviceWebLink,
  sse
};
