import { createSlice } from '@reduxjs/toolkit';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { user } : { user: null };

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(state, action) {
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload
      };
    },
    logout(state) {
      return {
        ...state,
        isLoggedIn: false,
        user: null
      };
    }
  }
});

export const { login, logout } = slice.actions;
export default slice.reducer;
