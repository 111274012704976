import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function UptimeString(seconds) {
  let output = '';

  if (seconds < 60) {
    output = seconds + (seconds > 1 ? ' seconds' : ' second');
  } else {
    const numdays = Math.floor(seconds / 86400);
    const numhours = Math.floor((seconds % 86400) / 3600);
    const numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    const numseconds = Math.floor(((seconds % 86400) % 3600) % 60);
    let count = 0;

    if (numdays > 0) {
      output = `${numdays}d`;
      count += 1;
    }
    if (numhours > 0) {
      output += `${(count > 0 ? ' ' : '') + numhours}h`;
      count += 1;
    }
    if (numminutes > 0) {
      output += `${(count > 0 ? ' ' : '') + numminutes}m`;
      count += 1;
    }
    if (count < 2 && numseconds > 0) {
      output += `${(count > 0 ? ' ' : '') + numseconds}s`;
    }
  }

  return output;
}
