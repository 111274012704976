import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import searchFill from '@iconify/icons-eva/search-fill';
import refreshOutline from '@iconify/icons-eva/refresh-outline';
import fileRemoveFill from '@iconify/icons-eva/file-remove-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import AccountTree from '@material-ui/icons/AccountTreeOutlined';
import SystemUpdateAltOutlined from '@material-ui/icons/SystemUpdateAltOutlined';
import pluralize from 'pluralize';
// import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAltIcon';
// material
import { styled } from '@material-ui/core/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  Button,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment
} from '@material-ui/core';

import {
  deregisterDevices,
  moveDevicesToNetwork,
  removeDevicesFromNetwork,
  upgradeDevicesFirmware,
  rebootDevices
} from '../../../actions/device';

import { getModelsFirmware } from '../../../actions/firmwares';

import { backdropShow, backdropClose } from '../../../actions/backdrop';
import { snackbarSuccess, snackbarError } from '../../../actions/snackbar';
import { dialogOpen } from '../../../actions/dialog';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
  columnGap: '1em'
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

DeviceListToolbar.propTypes = {
  selectedIds: PropTypes.array,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function DeviceListToolbar({
  type,
  devices,
  selected,
  filterName,
  onFilterName,
  networkItems,
  clearSelected
}) {
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state);

  const selectedDevices = useMemo(
    () => devices.filter((d) => selected.find((id) => id === d.id)),
    [devices, selected]
  );

  // console.log(orgs);
  // console.log(location);
  // console.log(networkItems);

  return (
    <RootStyle
      sx={{
        ...(selectedDevices.length > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {selectedDevices.length > 0 ? (
        <Typography component="div" variant="subtitle1">
          {selectedDevices.length} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="Name / MAC"
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}

      {selectedDevices.length > 0 ? (
        <>
          <Button
            style={{
              marginLeft: 'auto'
            }}
            disabled={selectedDevices.find((d) => d.network_id === null)}
            variant="contained"
            color="primary"
            startIcon={<Icon icon={refreshOutline} />}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              dispatch(
                dialogOpen(
                  'confirmItems',
                  'Confirm',
                  {
                    message: `Are you sure you want to reboot the following ${pluralize(
                      'device',
                      selectedDevices.length,
                      true
                    )}?`,
                    items: selectedDevices.map((d) => `${d.name} (${d.display_mac})`)
                  },
                  (data) =>
                    dispatch(rebootDevices(location.org_id, selectedDevices)).then(() =>
                      clearSelected()
                    )
                )
              );
            }}
          >
            Reboot
          </Button>
          <Button
            disabled={selectedDevices.find((d) => d.network_id === null)}
            variant="contained"
            color="primary"
            startIcon={<SystemUpdateAltOutlined />}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              dispatch(backdropShow());
              dispatch(getModelsFirmware())
                .then((modelFirmware) => {
                  const models = selectedDevices
                    .filter((d) => selectedDevices.find((dev) => d.model === dev.model) === d)
                    .map((d) => d.model);
                  const requiredModelfirmwares = {};
                  Object.entries(modelFirmware).forEach(([model, versions]) => {
                    if (models.find((m) => m === model) && versions.length) {
                      requiredModelfirmwares[model] = versions;
                    }
                  });
                  const selectedVersions = {};
                  Object.entries(requiredModelfirmwares).forEach(([model, versions]) => {
                    const [ver] = versions;
                    selectedVersions[model] = ver;
                  });
                  dispatch(
                    dialogOpen(
                      'upgradeDevicesFirmware',
                      'Firmware Upgrade',
                      {
                        models,
                        selectedVersions,
                        modelFirmwares: requiredModelfirmwares
                      },
                      (data) => {
                        const { selectedVersions } = data;
                        return dispatch(
                          upgradeDevicesFirmware(
                            location.org_id,
                            selectedDevices.filter((d) => selectedVersions[d.model]),
                            selectedVersions
                          )
                        ).then(() => clearSelected());
                      }
                    )
                  );
                })
                .catch((err) => dispatch(snackbarError(err.message)))
                .finally(() => dispatch(backdropClose()));
            }}
          >
            Firmware Upgrade
          </Button>
          <Button
            // style={{
            //   marginLeft: 'auto'
            // }}
            disabled={networkItems.length === 0}
            variant="contained"
            color="primary"
            startIcon={<AccountTree />}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              dispatch(
                dialogOpen(
                  'editDeviceNetwork',
                  'Edit Device Network',
                  {
                    action: 'move',
                    networkItems,
                    selectedNetworkID: networkItems[0].network_id
                  },
                  (data) => {
                    const { action, selectedNetworkID } = data;

                    if (action === 'move') {
                      return dispatch(
                        moveDevicesToNetwork(
                          location.org_id,
                          selectedNetworkID,
                          selectedDevices.filter((d) => d.network_id !== selectedNetworkID)
                        )
                      );
                    }

                    return dispatch(
                      removeDevicesFromNetwork(
                        location.org_id,
                        selectedDevices.filter((d) => d.network_id !== null)
                      )
                    );
                  }
                )
              );
            }}
          >
            Edit Network
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Icon icon={fileRemoveFill} />}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              dispatch(
                dialogOpen(
                  'confirmItems',
                  'Confirm',
                  {
                    message: `Are you sure you want to deregister the following ${pluralize(
                      'device',
                      selectedDevices.length,
                      true
                    )}?`,
                    items: selectedDevices.map((d) => `${d.name} (${d.display_mac})`)
                  },
                  (data) => dispatch(deregisterDevices(location.org_id, selectedDevices))
                )
              );
            }}
          >
            Deregister
          </Button>
          {/* <Tooltip title="Remove">
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                dispatch(
                  dialogOpen(
                    'removeDevices',
                    'Remove Device',
                    {
                      message: `Are you sure you want to remove the following device${
                        selectedDevices.length > 1 ? 's' : ''
                      }?`,
                      items: selectedDevices.map((d) => `${d.name} (${d.mac})`)
                    },
                    (data) => dispatch(deregisterDevices(location.org_id, selectedDevices))
                  )
                );
              }}
            >
              <Icon icon={trash2Fill} />
            </IconButton>
          </Tooltip> */}
        </>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <Icon icon={roundFilterList} />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}
