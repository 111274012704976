import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, Alert } from '@material-ui/core';
import { snackbarClose } from '../../actions/snackbar';

export default function SnackBar() {
  const { open, severity, message } = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(snackbarClose());
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      key="dashboardSnackbar"
    >
      <Alert
        style={{
          whiteSpace: 'pre-line'
        }}
        severity={severity}
        onClose={handleClose}
        variant="filled"
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
