import { combineReducers } from 'redux';
import user from './user';
import orgs from './orgs';
import devices from './devices';
import location from './location';
import snackbar from './snackbar';
import backdrop from './backdrop';
import dialog from './dialog';
import firmwares from './firmwares';
import members from './members';
import scan from './scan';

export default combineReducers({
  user,
  orgs,
  devices,
  location,
  snackbar,
  backdrop,
  dialog,
  firmwares,
  members,
  scan
});
