/* eslint-disable camelcase */
import { load } from '../reducers/firmwares';
import api from './api';

export const getModelsFirmware = (models) => (dispatch) =>
  api.call('getModelsFirmwares', {
    dispatch,
    ...(models && {
      queryParams: {
        model: models.join(',')
      }
    }),
    onResp: (firmwares) => {
      dispatch(load(firmwares));
      return firmwares;
    }
  });
