/* eslint-disable camelcase */
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import plusFill from '@iconify/icons-eva/plus-fill';
import fileAdd from '@iconify/icons-eva/file-add-fill';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import Edit from '@iconify/icons-eva/edit-2-outline';
import {
  Link,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Backdrop,
  IconButton,
  Tooltip
} from '@material-ui/core';

import api from '../actions/api';

import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import { MemberListToolbar, MemberMoreMenu } from '../components/_dashboard/member';
import { loadMembers, inviteMember } from '../actions/member';
import { snackbarError } from '../actions/snackbar';
import { backdropShow, backdropClose } from '../actions/backdrop';
import { dialogOpen } from '../actions/dialog';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  // { id: 'status', label: 'Status', alignRight: false },
  { id: 'org_permission', label: 'Org. Permission', alignRight: false },
  { id: 'network_permission', label: 'Networks Permission', alignRight: false },
  { id: 'last_login_at', label: 'Last Login', alignRight: false },
  { id: '' }
];

export default function Member() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [hoveringId, setHoveringId] = useState(null);
  const [myPermission, setMyPermission] = useState({ org: null, networks: [] });
  const subscribeSource = useRef(null);
  const location = useSelector((state) => state.location);
  const { members, total, reload } = useSelector((state) => state.members);
  const orgs = useSelector((state) => state.orgs);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const memberRows = useMemo(
    () =>
      members.filter((m) => {
        if (filterName.length === 0) return true;
        const reg = new RegExp(filterName, 'i');
        return m.name.match(reg) || m.email.match(reg);
      }),
    [members, filterName]
  );

  const inviteDialogItems = useMemo(() => {
    const items = { orgName: '', networks: [] };

    if (orgs) {
      const org = orgs.find((o) => o.id === location.org_id);
      if (org) {
        items.orgName = org.name;
        org.networks.forEach((n) => {
          if (location.network_id && n.id !== location.network_id) return;

          let matchGroup = false;
          let networkPath = n.name;
          for (let gid = n.group_id; gid != null; ) {
            if (location.group_id && gid === location.group_id) matchGroup = true;

            const group = org.groups.find((g) => g.id === gid);
            if (group) {
              networkPath = `${group.name} / ${networkPath}`;
              gid = group.parent_id;
            } else {
              break;
            }
          }

          if (location.group_id && !matchGroup) return;

          items.networks.push({
            path: networkPath,
            network_id: n.id,
            created_at: n.created_at
          });
        });
      }
    }

    items.networks.sort((a, b) => {
      if (a.networkPath > b.networkPath) return 1;
      if (a.networkPath < b.networkPath) return -1;
      if (a.created_at && b.created_at) {
        const d1 = new Date(a.created_at);
        const d2 = new Date(b.created_at);
        if (d1.getTime() < d2.getTime()) return -1;
        if (d1.getTime() > d2.getTime()) return 1;
      }
      return 0;
    });

    return items;
  }, [orgs, location]);

  useEffect(() => {
    if (location) {
      dispatch(backdropShow());
      dispatch(loadMembers(location))
        .then(() => setSelected([]))
        .catch((err) => dispatch(snackbarError(err.message)))
        .finally(() => dispatch(backdropClose()));
    }
  }, [location, reload]);

  useEffect(() => {
    const perm = members.find((m) => m.id === user.id);
    if (perm) {
      const { org, networks } = perm;
      setMyPermission({ org: org.permission, networks });
    }
  }, [members, user]);

  const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === 'asc';
    // setOrder(isAsc ? 'desc' : 'asc');
    // setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = members.map((m) => m.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - members.length) : 0;

  // const filteredUsers = applySortFilter(devices, getComparator(order, orderBy), filterName);

  // const isUserNotFound = filteredUsers.length === 0;

  // console.log('selected', selected);
  // console.log('devices', devices);

  return (
    <Page title="Member | Minimal-UI">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Member
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="#"
            disabled={myPermission.org !== 'admin' && inviteDialogItems.networks.length === 0}
            startIcon={<GroupAddIcon />}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              dispatch(
                dialogOpen(
                  'inviteMember',
                  'Invite New Member',
                  {
                    orgName: inviteDialogItems.orgName,
                    networkItems: inviteDialogItems.networks,
                    orgPermAllowed: myPermission.org === 'admin',
                    permType: myPermission.org === 'admin' ? 'org' : 'network',
                    networkID:
                      inviteDialogItems.networks.length > 0
                        ? inviteDialogItems.networks[0].network_id
                        : null,
                    email: '',
                    permission: 'admin'
                  },
                  (data) => {
                    const { permType, networkID, email, permission } = data;

                    return dispatch(
                      inviteMember(
                        {
                          org_id: location.org_id,
                          network_id: permType === 'network' ? networkID : null
                        },
                        email,
                        permission
                      )
                    );
                  }
                )
              );
            }}
          >
            Invite Member
          </Button>
        </Stack>

        <Card>
          <MemberListToolbar
            location={location}
            members={members}
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            networkItems={inviteDialogItems.networks}
            clearSelected={() => setSelected([])}
            myPermission={myPermission}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 900 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={members.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {memberRows.map((row) => {
                    const { id, name, email, org, networks, last_login_at } = row;
                    const isItemSelected = selected.indexOf(id) >= 0;
                    const networkPremission = { admin: [], viewer: [], none: [] };
                    // const net = [];
                    // net.push({
                    //   network_name: 'test',
                    //   permission: 'admin',
                    //   pending_permission: 'viewer'
                    // });
                    // net.push({
                    //   network_name: 'test2',
                    //   permission: 'viewer',
                    //   pending_permission: 'admin'
                    // });
                    networks.forEach((n) => {
                      const { id, network_name, permission, pending_permission } = n;
                      networkPremission[permission].push({
                        id,
                        network_name
                      });
                      networkPremission[pending_permission].push({
                        id,
                        network_name: `${network_name} (Pending)`
                      });
                    });

                    return (
                      <TableRow
                        hover
                        key={email}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                        onMouseEnter={(event) => {
                          event.stopPropagation();
                          // console.log('onMouseEnter', id, name);
                          setHoveringId(id);
                        }}
                        onMouseLeave={() => {
                          // console.log('onMouseLeave', id, name);
                          setHoveringId((pre) => (pre === id ? null : pre));
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, id)}
                          />
                        </TableCell>
                        {/* <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell> */}
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        {/* <TableCell align="left">{status}</TableCell> */}
                        <TableCell align="left">
                          {org.permission !== 'none' && (
                            <>
                              <Label
                                variant="ghost"
                                color={(org.permission === 'admin' && 'success') || 'secondary'}
                              >
                                {sentenceCase(org.permission)}
                              </Label>
                              <br />
                            </>
                          )}
                          {org.pending_permission !== 'none' && (
                            <>
                              <Label
                                variant="ghost"
                                color={
                                  (org.pending_permission === 'admin' && 'success') || 'secondary'
                                }
                              >
                                {`${sentenceCase(org.pending_permission)}`}
                              </Label>
                              <Typography variant="h10"> (Pending)</Typography>
                            </>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {networkPremission.admin.length > 0 && (
                            <>
                              <Label variant="ghost" color="success">
                                {sentenceCase('admin')}
                              </Label>
                              <Typography variant="h10">
                                {` ${networkPremission.admin
                                  .map((n) => n.network_name)
                                  .join(', ')}`}
                              </Typography>
                              <br />
                            </>
                          )}
                          {networkPremission.viewer.length > 0 && (
                            <>
                              <Label variant="ghost" color="secondary">
                                {sentenceCase('viewer')}
                              </Label>
                              <Typography variant="h10">
                                {` ${networkPremission.viewer
                                  .map((n) => n.network_name)
                                  .join(', ')}`}
                              </Typography>
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          {(last_login_at && moment(last_login_at).fromNow()) || ''}
                        </TableCell>

                        <TableCell align="right">
                          <MemberMoreMenu
                            location={location}
                            member={row}
                            networkItems={inviteDialogItems.networks}
                            myPermission={myPermission}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {/* {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
