/* eslint-disable camelcase */
import { login as userLogin, logout as userLogout } from '../reducers/user';
import { hide as hideSnackbar } from '../reducers/snackbar';
import { hide as hideDialog } from '../reducers/dialog';

import api from './api';

export const register =
  (email, password, first_name, last_name, country_code, timezone) => (dispatch) =>
    api.call('signup', {
      dispatch,
      data: {
        email,
        password,
        first_name,
        last_name,
        country_code,
        timezone
      }
    });

export const login = (email, password) => (dispatch) =>
  api.call('signin', {
    dispatch,
    data: {
      email,
      password
    },
    onResp: (data) => {
      localStorage.setItem('user', JSON.stringify(data));
      dispatch(hideSnackbar());
      dispatch(hideDialog());
      dispatch(userLogin(data));
      return data;
    }
  });

export const logout = () => (dispatch) => {
  localStorage.removeItem('user');
  dispatch(userLogout());
};

export const confirm = (token) => (dispatch) =>
  api.call('confirm', {
    dispatch,
    data: {
      token
    }
  });

export const resetPassword = (token, password) => (dispatch) =>
  api.call('confirm', {
    dispatch,
    data: {
      token,
      password
    }
  });
