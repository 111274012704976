/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

const initialState = { members: [], total: 0, reload: 0 };

const slice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    load(state, action) {
      const { total, members } = action.payload;
      state.total = total;
      state.members = members;
      // action.payload.forEach((m) => state.members.push(m));
    },
    set(state, action) {
      const { id, org, networks } = action.payload;
      const idx = state.members.findIndex((m) => m.id === id);
      if (idx >= 0) {
        state.members[idx] = {
          ...state.members[idx],
          ...action.payload,
          ...(org !== undefined && {
            org: { ...org }
          }),
          ...(networks !== undefined && {
            networks: Object.assign([], networks)
          })
        };
      } else {
        console.warn(`set a non-existent member ${id}!`);
      }
    },
    networkAdd(state, action) {
      const { id, network } = action.payload;
      const idx = state.members.findIndex((m) => m.id === id);
      if (idx >= 0) {
        const nidx = state.members[idx].networks.findIndex((n) => n.id === network.id);
        if (nidx < 0) {
          state.members[idx].networks.push({
            ...network
          });
        } else {
          console.warn(`add a existent network ${network.id} from a member ${id}!`);
          state.members[idx].networks[nidx] = {
            ...network
          };
        }
      } else {
        console.warn(`add a network ${network.id} from a non-existent member ${id}!`);
      }
    },
    networkDel(state, action) {
      const { id, network } = action.payload;
      if (id) {
        const idx = state.members.findIndex((m) => m.id === id);
        if (idx >= 0) {
          state.members[idx].networks = state.members[idx].networks.filter(
            (n) => n.id !== network.id
          );
        } else {
          console.warn(`del a network $network.id} from a non-existent member ${id}!`);
        }
      } else {
        for (let idx = 0; idx < state.members.length; idx += 1) {
          state.members[idx].networks = state.members[idx].networks.filter(
            (n) => n.id !== network.id
          );
        }
      }
    },
    networkSet(state, action) {
      const { id, network } = action.payload;
      if (id) {
        const idx = state.members.findIndex((m) => m.id === id);
        if (idx >= 0) {
          const nidx = state.members[idx].networks.findIndex((n) => n.id === network.id);
          if (nidx >= 0) {
            state.members[idx].networks[nidx] = {
              ...state.members[idx].networks[nidx],
              ...network
            };
          } else {
            console.warn(`set a non-existent network ${network.id} from a member ${id}!`);
          }
        } else {
          console.warn(`set a network ${network.id} from a non-existent member ${id}!`);
        }
      } else {
        for (let idx = 0; idx < state.members.length; idx += 1) {
          const nidx = state.members[idx].networks.findIndex((n) => n.id === network.id);
          if (nidx >= 0) {
            state.members[idx].networks[nidx] = {
              ...state.members[idx].networks[nidx],
              ...network
            };
          }
        }
      }
    },
    triggerReload(state, action) {
      state.reload += 1;
    }
  }
});

export const { load, del, set, networkAdd, networkDel, networkSet, triggerReload } = slice.actions;
export default slice.reducer;
