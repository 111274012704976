import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
//
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import DashboardSnackbar from './DashboardSnackbar';
import DashboardBackdrop from './DashboardBackdrop';
import DashboardDialog from './DashboardDialog';

import { loadOrgs, addOrg } from '../../actions/org';
import { initLocation } from '../../actions/location';
import { snackbarError } from '../../actions/snackbar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function initOrgData() {
      try {
        setLoading(true);
        let orgs = await dispatch(loadOrgs());
        if (orgs.length === 0) {
          await dispatch(addOrg(user.first_name, user.country_code, user.timezone));
          orgs = await dispatch(loadOrgs());
        }
        await dispatch(initLocation(orgs));
        setLoading(false);
      } catch (err) {
        dispatch(snackbarError(err.message));
      }
    }
    if (user) {
      initOrgData();
    } else {
      navigate('/login');
    }
  }, [user]);

  if (!user) {
    return null;
  }

  if (loading) {
    return (
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <RootStyle>
      <DashboardDialog />
      <DashboardSnackbar />
      <DashboardBackdrop />
      <DashboardNavbar onOpenSidebar={() => setIsOpenSidebar(true)} />
      <DashboardSidebar
        isOpenSidebar={isOpenSidebar}
        onCloseSidebar={() => setIsOpenSidebar(false)}
      />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
