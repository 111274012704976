import { createSlice } from '@reduxjs/toolkit';

const initialState = { open: false };

const slice = createSlice({
  name: 'backdrop',
  initialState,
  reducers: {
    show(state) {
      return {
        open: true
      };
    },
    hide(state) {
      return {
        open: false
      };
    }
  }
});

export const { show, hide } = slice.actions;
export default slice.reducer;
