/* eslint-disable camelcase */
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import plusFill from '@iconify/icons-eva/plus-fill';
import fileAdd from '@iconify/icons-eva/file-add-fill';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import Edit from '@iconify/icons-eva/edit-2-outline';
import ExternalLink from '@iconify/icons-eva/external-link-outline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// import WifiFindIcon from '@material-ui/icons/WifiFind';
import WifiFindIcon from '@mui/icons-material/WifiFind';
import macaddr from 'macaddr';
import pluralize from 'pluralize';

import {
  Collapse,
  Box,
  Link,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Backdrop,
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';

import api from '../actions/api';

import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import ClientList from '../layouts/dashboard/ClientList';
import ScanDialog from '../layouts/dashboard/ScanDialog';
import { DeviceListToolbar, DeviceMoreMenu } from '../components/_dashboard/device';
import { UptimeString } from '../utils/formatTime';
import { FreqToChannel, ChannelFormat } from '../utils/channel';
import {
  loadDevices,
  registerDevices,
  modifyDeviceName,
  subscribeDevicesStatus,
  configChannel
} from '../actions/device';
import { snackbarError } from '../actions/snackbar';
import { backdropShow, backdropClose } from '../actions/backdrop';
import { dialogOpen } from '../actions/dialog';

const TABLE_HEAD = [
  // { id: 'more', label: '', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'serial_number', label: 'Serial Number', alignRight: false },
  { id: 'mac', label: 'MAC', alignRight: false },
  { id: 'model', label: 'Model', alignRight: false },
  { id: 'network_name', label: 'Network', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'fw_version', label: 'Firmware', alignRight: false },
  { id: 'uptime', label: 'Uptime', alignRight: false },
  // { id: 'wan_ip', label: 'WAN IP', alignRight: false },
  { id: 'lan_ip', label: 'LAN IP', alignRight: false },
  // { id: 'usage', label: 'CPU / Mem. Usage', alignRight: false },
  { id: 'channel_2_4g', label: '2.4G CH / Width', alignRight: false },
  { id: 'channel_5g', label: '5G CH / Width', alignRight: false },
  { id: 'clients', label: 'Connections', alignRight: false },
  // { id: 'local_web', label: 'Local Web', alignRight: false },
  { id: '' }
];

function DeviceRow(props) {
  const {
    row,
    hoveringId,
    setHoveringId,
    setClientListOpen,
    setScanDialogOpen,
    selected,
    handleClick,
    location,
    networkItems,
    bssidDeviceMap
  } = props;
  // const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const {
    id,
    org_id,
    network_id,
    name,
    model,
    network_name,
    mac,
    display_mac,
    connection,
    radios,
    uptime,
    lan_ip
  } = row;
  const { online, firmware_version, update_at } = connection;
  const isItemSelected = selected.indexOf(id) >= 0;
  // const clientNum = `${radios?.band_2g?.client_num ?? ''} / ${radios?.band_5g?.client_num ?? ''}`;
  const channel_2_4g =
    (radios?.band_2g &&
      ChannelFormat(
        radios.band_2g.channel,
        radios.band_2g.channel_width,
        radios.band_2g.center_freq1,
        radios.band_2g.center_freq2
      )) ||
    '';
  const channel_5g =
    (radios?.band_5g &&
      ChannelFormat(
        radios.band_5g.channel,
        radios.band_5g.channel_width,
        radios.band_5g.center_freq1,
        radios.band_5g.center_freq2
      )) ||
    '';

  // let displayMAC = mac;
  // if (model === 'PT420' || model === 'PT880') {
  //   const addrNum = macaddr.parse(mac)._value + 3;
  //   displayMAC = macaddr.parse(addrNum).toString();
  // }

  const connections = (radios) => {
    if (!radios) return null;
    const { band_2g, band_5g } = radios;
    let connection_2_4g = '';
    if (band_2g) {
      const band_2g_client = band_2g?.client_num ?? 0;
      const band_2g_mode = band_2g?.mode ?? 'AP';
      if (band_2g_mode !== 'managed') {
        connection_2_4g = `2.4G: ${pluralize('client', band_2g_client, true)}`;
      } else if (band_2g_client > 0) {
        const d = bssidDeviceMap[band_2g.clients[0].mac];
        if (d) {
          connection_2_4g = `2.4G: ${d.name}`;
        } else {
          connection_2_4g = `2.4G: ${band_2g.clients[0].mac}`;
        }
      }
    }

    let connection_5g = '';
    if (band_5g) {
      const band_5g_client = band_5g?.client_num ?? 0;
      const band_5g_mode = band_5g?.mode ?? 'AP';
      if (band_5g_mode !== 'managed') {
        connection_5g = `5G: ${pluralize('client', band_5g_client, true)}`;
      } else if (band_5g_client > 0) {
        const d = bssidDeviceMap[band_5g.clients[0].mac];
        if (d) {
          connection_5g = `5G: ${d.name}`;
        } else {
          connection_5g = `5G: ${band_5g.clients[0].mac}`;
        }
      }
    }

    return (
      <>
        {band_2g && (
          <Box display="flex" justifyContent="flex-start" alignItems="left">
            <Link
              href="#"
              underline="none"
              onClick={(event) => {
                event.stopPropagation();
                setClientListOpen(mac);
              }}
            >
              {connection_2_4g}
            </Link>
            {online && (
              <Tooltip title="Scan">
                <Link
                  sx={{
                    marginLeft: 1,
                    marginTop: 0.3
                  }}
                  href="#"
                  underline="none"
                  onClick={(event) => {
                    event.stopPropagation();
                    setScanDialogOpen(mac, 'band_2g');
                  }}
                >
                  <WifiFindIcon sx={{ fontSize: 18 }} />
                </Link>
              </Tooltip>
            )}
          </Box>
        )}
        {band_5g && (
          <Box display="flex" justifyContent="flex-start" alignItems="left">
            <Link
              href="#"
              underline="none"
              onClick={(event) => {
                event.stopPropagation();
                setClientListOpen(mac);
              }}
            >
              {connection_5g}
            </Link>
            {online && (
              <Tooltip title="Scan">
                <Link
                  sx={{
                    marginLeft: 1,
                    marginTop: 0.3
                  }}
                  href="#"
                  underline="none"
                  onClick={(event) => {
                    event.stopPropagation();
                    setScanDialogOpen(mac, 'band_5g');
                  }}
                >
                  <WifiFindIcon sx={{ fontSize: 18 }} />
                </Link>
              </Tooltip>
            )}
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <TableRow
        hover
        key={id}
        tabIndex={-1}
        role="checkbox"
        selected={isItemSelected}
        aria-checked={isItemSelected}
        onMouseEnter={(event) => {
          event.stopPropagation();
          setHoveringId(id);
        }}
        onMouseLeave={() => {
          setHoveringId((pre) => (pre === id ? null : pre));
        }}
      >
        <TableCell padding="checkbox">
          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
        </TableCell>
        {/* <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell> */}
        {/* <TableCell component="th" scope="row" padding="none">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar alt={name} src={avatarUrl} />
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Stack>
      </TableCell> */}
        <TableCell align="left">
          {(hoveringId !== id && name) || (
            <>
              {name}
              <Tooltip title="Edit Name">
                <IconButton
                  edge="end"
                  sx={{
                    marginLeft: 1
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    dispatch(
                      dialogOpen('modifyDeviceName', 'Edit Device Name', { name }, (data) => {
                        const { name } = data;
                        return dispatch(modifyDeviceName(org_id, network_id, id, name));
                      })
                    );
                  }}
                >
                  <Icon icon={Edit} width={20} height={20} />
                </IconButton>
              </Tooltip>
            </>
          )}
        </TableCell>
        <TableCell align="left">{display_mac}</TableCell>
        <TableCell align="left">{model}</TableCell>
        <TableCell align="left">{network_name}</TableCell>
        <TableCell align="left">
          <Box display="flex" justifyContent="flex-start" alignItems="left">
            {network_id && (
              <Label variant="ghost" color={(online !== true && 'default') || 'success'}>
                {sentenceCase(
                  online
                    ? 'online'
                    : `offline${(update_at && ` ${moment(update_at).fromNow()}`) || ''}`
                )}
              </Label>
            )}
            {online && (
              <Tooltip title="Local Web">
                <Link
                  href={api.deviceWebLink(org_id, network_id, id)}
                  target="_blank"
                  sx={{
                    marginLeft: 1
                  }}
                >
                  <Icon icon={ExternalLink} width={20} height={20} />
                </Link>
              </Tooltip>
            )}
          </Box>
        </TableCell>
        <TableCell align="left">{firmware_version}</TableCell>
        <TableCell align="left">{uptime && UptimeString(uptime)}</TableCell>
        <TableCell align="left">{lan_ip}</TableCell>
        <TableCell align="left">
          {online && (
            <Box display="flex" alignItems="center">
              <Link
                href="#"
                underline="none"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  dispatch(
                    dialogOpen(
                      'configChannel',
                      `${name} 2.4GHz Channel Settings`,
                      {
                        band: 'band_2g',
                        selectedChannel: radios?.band_2g?.channel || 0,
                        selectedChannelWidth: radios?.band_2g?.channel_width || 20
                      },
                      (data) => {
                        const { band, selectedChannel, selectedChannelWidth } = data;
                        const network = networkItems.find((n) => n.network_id === network_id);
                        console.log(band, selectedChannel, selectedChannelWidth);
                        return dispatch(
                          configChannel(
                            org_id,
                            network_id,
                            id,
                            band,
                            selectedChannel,
                            selectedChannelWidth,
                            network.country_code
                          )
                        );
                      }
                    )
                  );
                }}
              >
                {channel_2_4g.length > 0 ? channel_2_4g : '--'}
              </Link>
            </Box>
          )}
        </TableCell>
        <TableCell align="left">
          {' '}
          {online && (
            <Box display="flex" alignItems="center">
              <Link
                href="#"
                underline="none"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  dispatch(
                    dialogOpen(
                      'configChannel',
                      `${name} 5GHz Channel Settings`,
                      {
                        band: 'band_5g',
                        selectedChannel: radios?.band_5g?.channel || 0,
                        selectedChannelWidth: radios?.band_5g?.channel_width || 20
                      },
                      (data) => {
                        const { band, selectedChannel, selectedChannelWidth } = data;
                        console.log(band, selectedChannel, selectedChannelWidth);
                        const network = networkItems.find((n) => n.network_id === network_id);
                        return dispatch(
                          configChannel(
                            org_id,
                            network_id,
                            id,
                            band,
                            selectedChannel,
                            selectedChannelWidth,
                            network.country_code
                          )
                        );
                      }
                    )
                  );
                }}
              >
                {channel_5g.length > 0 ? channel_5g : '--'}
              </Link>
            </Box>
          )}
        </TableCell>
        <TableCell align="left">{connections(radios)}</TableCell>
        <TableCell align="right">
          <DeviceMoreMenu device={row} location={location} networkItems={networkItems} />
        </TableCell>
      </TableRow>
    </>
  );
}

export default function AccessPoint() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [clientListStatus, setClientListStatus] = useState({ open: false, mac: null });
  const [scanDialogStatus, setscanDialogStatus] = useState({ open: false, mac: null });
  const [orderBy, setOrderBy] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [hoveringId, setHoveringId] = useState(null);
  const subscribeSource = useRef(null);
  const location = useSelector((state) => state.location);
  const { devices, total, reload } = useSelector((state) => state.devices);
  const orgs = useSelector((state) => state.orgs);
  const dispatch = useDispatch();

  const deviceRows = useMemo(() => {
    const rows = devices.filter((d) => {
      if (searchFilter.length === 0) return true;
      const reg = new RegExp(searchFilter, 'i');
      // console.log(d.name.match(reg));
      // console.log(d.display_mac.match(reg));
      return d.name.match(reg) || d.display_mac.match(reg);
    });
    if (orderBy.length > 0) {
      rows.sort((a, b) => {
        let aVal;
        let bVal;
        switch (orderBy) {
          case 'local_web':
          case 'status':
            aVal = a?.connection?.online ?? false;
            bVal = b?.connection?.online ?? false;
            if (!aVal && !bVal) {
              aVal = a?.connection?.update_at ?? 0;
              bVal = b?.connection?.update_at ?? 0;
            }
            break;
          case 'fw_version':
            aVal = a?.connection?.firmware_version ?? '';
            bVal = b?.connection?.firmware_version ?? '';
            break;
          case 'wan_ip':
            aVal = a?.connection?.wan_ip ?? '';
            bVal = b?.connection?.wan_ip ?? '';
            break;
          case 'lan_ip':
            aVal = a?.lan_ip ?? '';
            bVal = b?.lan_ip ?? '';
            break;
          case 'uptime':
            aVal = a?.uptime ?? 0;
            bVal = b?.uptime ?? 0;
            break;
          case 'channel_2_4g':
            aVal = FreqToChannel(a?.radios?.band_2g?.center_freq1 ?? 0);
            if (aVal === 0) {
              aVal = a?.radios?.band_2g?.channel || -1;
            }
            bVal = FreqToChannel(b?.radios?.band_2g?.center_freq1 ?? 0);
            if (bVal === 0) {
              bVal = b?.radios?.band_2g?.channel || -1;
            }
            break;
          case 'channel_5g':
            aVal = FreqToChannel(a?.radios?.band_5g?.center_freq1 ?? 0);
            if (aVal === 0) {
              aVal = a?.radios?.band_5g?.channel || -1;
            }
            bVal = FreqToChannel(b?.radios?.band_5g?.center_freq1 ?? 0);
            if (bVal === 0) {
              bVal = b?.radios?.band_5g?.channel || -1;
            }
            break;
          case 'clients':
            aVal = (a?.radios?.band_2g?.client_num ?? 0) + (a?.radios?.band_5g?.client_num ?? 0);
            bVal = (b?.radios?.band_2g?.client_num ?? 0) + (b?.radios?.band_5g?.client_num ?? 0);
            if (aVal === 0 && bVal === 0) {
              aVal = a?.connection?.online ?? false;
              bVal = b?.connection?.online ?? false;
            }
            break;
          default:
            aVal = a[orderBy];
            bVal = b[orderBy];
        }
        // console.log(aVal, bVal);
        if (aVal === bVal) return 0;
        if (aVal > bVal) return order === 'asc' ? -1 : 1;
        return order === 'asc' ? 1 : -1;
      });
    }
    return rows;
  }, [devices, order, orderBy, searchFilter]);

  const bssidDeviceMap = useMemo(() => {
    const map = {};
    devices
      .filter((d) => d.radios !== undefined)
      .forEach((d) => {
        Object.entries(d.radios).forEach(([type, radio]) => {
          const { bssid } = radio;
          map[bssid] = d;
        });
      });
    return map;
  }, [devices]);

  const networkItems = useMemo(() => {
    const items = [];

    if (orgs) {
      const org = orgs.find((o) => o.id === location.org_id);
      if (org) {
        org.networks.forEach((n) => {
          if (location.network_id && n.id !== location.network_id) return;

          let matchGroup = false;
          let networkPath = n.name;
          for (let gid = n.group_id; gid != null; ) {
            if (location.group_id && gid === location.group_id) matchGroup = true;

            const group = org.groups.find((g) => g.id === gid);
            if (group) {
              networkPath = `${group.name} / ${networkPath}`;
              gid = group.parent_id;
            } else {
              break;
            }
          }

          if (location.group_id && !matchGroup) return;

          items.push({
            path: networkPath,
            network_id: n.id,
            created_at: n.created_at,
            country_code: n.country_code
          });
        });
      }
    }

    items.sort((a, b) => {
      if (a.networkPath > b.networkPath) return 1;
      if (a.networkPath < b.networkPath) return -1;
      if (a.created_at && b.created_at) {
        const d1 = new Date(a.created_at);
        const d2 = new Date(b.created_at);
        if (d1.getTime() < d2.getTime()) return -1;
        if (d1.getTime() > d2.getTime()) return 1;
      }
      return 0;
    });

    return items;
  }, [orgs, location]);

  const networkIds = useMemo(() => {
    const ids = [];
    if (orgs) {
      const org = orgs.find((o) => o.id === location.org_id);
      if (org) {
        org.networks.forEach((n) => {
          if (location.network_id && n.id !== location.network_id) return;

          if (location.group_id) {
            let matchGroup = false;
            for (let gid = n.group_id; gid != null; ) {
              if (gid === location.group_id) {
                matchGroup = true;
                break;
              }

              const group = org.groups.find((g) => g.id === gid);
              if (group) {
                gid = group.parent_id;
              } else {
                break;
              }
            }

            if (!matchGroup) return;
          }

          ids.push(n.id);
        });
      }
    }

    return ids;
  }, [orgs, location]);

  useEffect(() => {
    if (location) {
      dispatch(backdropShow());
      dispatch(loadDevices(location, {}))
        .then(() => setSelected([]))
        .catch((err) => dispatch(snackbarError(err.message)))
        .finally(() => dispatch(backdropClose()));
    }
  }, [location, reload]);

  useEffect(() => {
    if (subscribeSource.current) {
      subscribeSource.current.close();
      subscribeSource.current = null;
    }
    if (networkIds.length) {
      subscribeSource.current = dispatch(
        subscribeDevicesStatus(networkIds, { deviceTypes: ['ap'] })
      );
    }
    return () => {
      if (subscribeSource.current) subscribeSource.current.close();
    };
  }, [networkIds]);

  const setClientListClose = useCallback(
    () =>
      setClientListStatus((pre) => ({
        open: false,
        mac: null
      })),
    []
  );

  const setClientListOpen = useCallback(
    (mac) =>
      setClientListStatus((pre) => ({
        open: true,
        mac
      })),
    []
  );

  const setScanDialogClose = useCallback(
    () =>
      setscanDialogStatus((pre) => ({
        open: false,
        mac: null
      })),
    []
  );

  const setScanDialogOpen = useCallback((mac, band) => {
    setClientListClose();
    setscanDialogStatus((pre) => ({
      open: true,
      mac,
      band
    }));
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    // console.log('orderBy', property, 'isAsc', isAsc);
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = devices.map((d) => d.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchFilter = (event) => {
    console.log(event.target.value);
    setSearchFilter(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - devices.length) : 0;

  // const filteredUsers = applySortFilter(devices, getComparator(order, orderBy), filterName);

  // const isUserNotFound = filteredUsers.length === 0;

  // console.log('selected', selected);
  // console.log('devices', devices);

  return (
    <Page title="Access Point | Minimal-UI">
      <Container maxWidth="false">
        <ClientList
          device={devices.find((d) => clientListStatus.mac === d.mac)}
          isOpen={clientListStatus.open}
          setClose={setClientListClose}
          bssidDeviceMap={bssidDeviceMap}
        />
        <ScanDialog
          device={devices.find((d) => scanDialogStatus.mac === d.mac)}
          isOpen={scanDialogStatus.open}
          setClose={setScanDialogClose}
          bssidDeviceMap={bssidDeviceMap}
          band={scanDialogStatus.band}
        />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Access Point
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={fileAdd} />}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              dispatch(
                dialogOpen('registerDevices', 'Register Device', { text: '' }, (data) => {
                  const { text } = data;
                  const serialNumbers = text.split('\n').filter((s) => s.length > 0);
                  return dispatch(registerDevices(location.org_id, serialNumbers));
                })
              );
            }}
          >
            Register Device
          </Button>
        </Stack>

        <Card>
          <DeviceListToolbar
            location={location}
            devices={deviceRows}
            selected={selected}
            filterName={searchFilter}
            onFilterName={handleSearchFilter}
            networkItems={networkItems}
            clearSelected={() => setSelected([])}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 900 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={devices.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {deviceRows.map((row, idx) => (
                    <DeviceRow
                      key={idx}
                      row={row}
                      hoveringId={hoveringId}
                      setHoveringId={setHoveringId}
                      setClientListOpen={setClientListOpen}
                      setScanDialogOpen={setScanDialogOpen}
                      selected={selected}
                      handleClick={handleClick}
                      location={location}
                      networkItems={networkItems}
                      bssidDeviceMap={bssidDeviceMap}
                    />
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {/* {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
