/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const slice = createSlice({
  name: 'firmwares',
  initialState,
  reducers: {
    load(state, action) {
      return action.payload;
    }
  }
});

export const { load } = slice.actions;
export default slice.reducer;
