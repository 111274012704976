/* eslint-disable camelcase */
import { load, triggerReload } from '../reducers/members';
import api from './api';

export const loadMembers = (location) => (dispatch) => {
  const { group_id, network_id } = location;
  let apiName = 'getOrgMembers';

  if (network_id && network_id.length > 0) {
    apiName = 'getNetworkMembers';
  } else if (group_id && group_id.length > 0) {
    apiName = 'getGroupMembers';
  }

  return api.call(apiName, {
    dispatch,
    urlParams: location,
    onResp: (members) => {
      dispatch(load(members));
    }
  });
};

export const inviteMember =
  ({ org_id, network_id }, email, permission) =>
  (dispatch) => {
    const urlParams = { org_id };
    let apiName = 'inviteOrgMember';

    if (network_id) {
      apiName = 'inviteNetworkMember';
      urlParams.network_id = network_id;
    }

    return api.call(apiName, {
      dispatch,
      urlParams,
      data: {
        email,
        permission
      },
      onResp: () => {
        dispatch(triggerReload());
      }
    });
  };

export const removeMembers = (org_id, myPermission, members) => (dispatch) =>
  Promise.allSettled(
    members.map((member) =>
      api.call('modifyMemberPermission', {
        dispatch,
        urlParams: { org_id },
        data: {
          email: member.email,
          ...(myPermission.org === 'admin' && { org_permission: 'none' }),
          networks: member.networks
            .filter(
              (n) =>
                myPermission.org === 'admin' ||
                myPermission.networks.find((nw) => nw.id === n.id && nw.permission === 'admin')
            )
            .map((n) => ({
              id: n.id,
              permission: 'none'
            }))
        },
        onError: (error) => ({ message: error.message, member })
      })
    )
  ).then((results) => {
    let success = 0;
    let message = '';
    results.forEach((r) => {
      if (r.status === 'fulfilled') success += 1;
      else {
        if (message.length > 0) message += '\n';
        const { name, email } = r.reason.member;
        message += `${name.length > 0 ? `${name} (${email})` : email}: ${r.reason.message}`;
      }
    });

    if (success > 0) {
      dispatch(triggerReload());
    }

    if (message.length > 0) {
      throw new Error(message);
    }
  });
