/* eslint-disable camelcase */
import { set } from '../reducers/location';

export const initLocation = (orgs) => (dispatch) => {
  const preLocation = JSON.parse(localStorage.getItem('location'));
  let location = null;

  if (orgs.length === 0) {
    throw new Error('no org available');
  }

  if (preLocation) {
    const { org_id, group_id, network_id } = preLocation;
    if (org_id) {
      const org = orgs.find((o) => o.id === org_id);
      if (org) {
        if (network_id) {
          const network = org.networks.find((n) => n.id === network_id);
          if (network) {
            location = { org_id, network_id };
          }
        }

        if (!location && group_id) {
          const group = org.groups.find((g) => g.id === group_id);
          if (group) {
            location = { org_id, group_id };
          }
        }

        if (!location) {
          location = { org_id };
        }
      }
    }
  }

  if (!location) {
    location = { org_id: orgs[0].id };
  }

  console.log('location =>', location);
  localStorage.setItem('location', JSON.stringify(location));
  dispatch(set(location));

  return location;
};

export const setLocation = (location) => (dispatch) => {
  console.log('location =>', location);
  localStorage.setItem('location', JSON.stringify(location));
  dispatch(set(location));
};
