import { createSlice } from '@reduxjs/toolkit';

const location = JSON.parse(localStorage.getItem('location'));
const initialState = location || {};

const slice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    set(state, action) {
      return action.payload;
    }
  }
});

export const { set } = slice.actions;
export default slice.reducer;
