import { show, hide } from '../reducers/snackbar';

export const snackbarSuccess = (message) => (dispatch) => {
  dispatch(
    show({
      severity: 'success',
      message
    })
  );
};

export const snackbarError = (message) => (dispatch) => {
  dispatch(
    show({
      severity: 'error',
      message
    })
  );
};

export const snackbarInfo = (message) => (dispatch) => {
  dispatch(
    show({
      severity: 'info',
      message
    })
  );
};

export const snackbarWarning = (message) => (dispatch) => {
  dispatch(
    show({
      severity: 'warning',
      message
    })
  );
};

export const snackbarClose = () => (dispatch) => {
  dispatch(hide());
};
